import  React, {useState} from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link } from 'react-router-dom';
import Modal from "./Modal"
import styles from "../../Reports/Modal.module.css";
export default function data(props)
 {
    const DATA = props; 
    const [isOpen, setIsOpen] = useState(false);
    const description ="Staff Qualification's Dialog";
  
  return {
    columns: [
        { Header: "SN", accessor: "sn", width: "5%", align: "left" },
        { Header: "FileNo.", accessor: "staffID", align: "left" },
        { Header: "Qualifications", accessor: "qualificationID", align: "left" },
        { Header: "CreatedAt", accessor: "createdAt", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
        
      ],
  
      row: [

         DATA.map((item, i)=>(
         {             
            sn:     (++i),
            staffID: (item.staffID),
            qualificationID: (item.qualificationID),
            createdAt: (item.createdAt),
            action: (<MDTypography component="a" variant="caption" color="text"  fontWeight="medium">
                         <MDButton className={styles.primaryBtn}  color="error" size="sm" onClick={() => setIsOpen(true)}>Delete</MDButton>
                          {isOpen && <Modal setIsOpen={setIsOpen} description={description} uid={item.id} setTitle={item.qualificationIS} />}
                    </MDTypography>)    
        }))          
      ],
   
  };
}