/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "./UsersTableColumns";
import DataTable from "pages/Tables/DataTable";

function Index() 
{
  const baseUrl     = process.env.REACT_APP_BASE_URL;
  const baseUrlUser = process.env.REACT_APP_BASE_URL_USER;
  const [dataRoles, setRolesData] =useState([]);
  const [userinfo, setUserInfo] =useState([]);
  const [selectRoleValue, setselectRoleValue] = useState();
  const [fieldValue, setFieldValue]=useState();
  let createdBy= null;
  let role = sessionStorage.getItem('role');
  let unit = sessionStorage.getItem('unit');
  let email = sessionStorage.getItem('email');
  console.log('Email Address ', email);
 const handleSubmit =(e)=>
  {
    e.preventDefault();
 
    if(role=='Admin') createdBy = sessionStorage.getItem('email');
 
    const userData ={
        lastname:e.target.surname.value,
        firstname:e.target.firstname.value,
        phone:e.target.phone.value,
        othername:e.target.othername.value,
        email:e.target.email.value,
        password:e.target.password.value,
        role:selectRoleValue.value,
        staffID:e.target.staffID.value,
        createdBy:createdBy,
        userID :"0",
        unitID : Number(unit)

      }  
     
    
      axios.post(`${baseUrl}register-admin`, userData).then(response => 
        {
            console.log(response);
            if(response.data.statusCode === 0)
              {
               
                
                setSearchResults(customHeadings) 
                setResults(customHeadings) 
                toast.success('User Created Successfully');
                setselectRoleValue('');
                
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer);            
                
            }
           else
            { 
           
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
             // window.location.reload(true);  
          }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
   const fetchRolesData=()=>
   {         
     axios.get(`${baseUrl}GetAllRoles`).then(response => 
     {
        
        const customHeadings = response.data.map(item=>({
             "value":item.id,
                   "label": item.name,
                  
                 }))
                 setRolesData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }

   const fetchUsersData=()=>
   {         
     axios.get(`${baseUrlUser}GetAllUsers`).then(response => 
     {
      
        const customHeadings = response.data.map(item=>({
                    "id": item.id,
                    "username" :item.username,
                    "phone": item.phoneNumber,
                    "lastname":  item.lastName,
                    "firstname": item.firstName,
                    "email":  item.email,
                    "staffID": item.staffID,
                    "role": item.userRole,
                    "createdBy": item.createdBy,
                    "createdAt": item.createdAt                 
                 }))
                 setUserInfo(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }

   useEffect(()=>{
    fetchRolesData();
    fetchUsersData();
   },[])   


const { columns, row } = TableColumns(userinfo);
const rows =row[0]; //Reduce double array to single array

const selectRoleOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectRoleValue(selectedOption);
};

const surNameOnChange = (selectedOption) => 
{
  setFieldValue('surname', selectedOption.value);
  setSurname(selectedOption);
};




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Add User</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Add Users
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
               
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={2}>
                        <Grid container spacing={1}>
                            <Grid item xs={4}>
                                 <MDBox mb={1}>
                                     <MDInput type="text" label="Surname"  name="surname" id="surname"  fullWidth required />
                                 </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Firstname" name="firstname" id="firstname" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Othertname" name="othername" id="staffIID" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="File Number" name="staffID" id="staffID" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Phone Number" name="phone" id="phone" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>                 
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    
                                      <Select placeholder='Role' name="searchValueCategory" value={selectRoleValue} onChange={selectRoleOnChange} options={dataRoles}   />
                              </MDTypography>  
                            </Grid>     
                            <Grid item xs={8}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Email Address" name="email" id="email" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="password" label="Password" name="password" id="password" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={12} sm={12} lg={10}>                             
                                <Grid item xs={6}>
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="warning" size="sm">Create User</MDButton>
                                    </MDTypography>
                                </Grid>
                             </Grid>
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
              
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {userinfo.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User's Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
