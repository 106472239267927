/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import React,{useState} from "react";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link} from "react-router-dom";
import Modal from "./Modal";
import styles from "./SearchApp.module.css";
export default function data(props) {
  const [isOpen, setIsOpen] = useState(false);
  return {
    columns: [
      { Header: "SN", accessor: "sn", width: "5%", align: "center" },
      { Header: "FileNo", accessor: "fileno", align: "center" },
      //{ Header: "Title", accessor: "title", align: "center" },
      { Header: "Surname", accessor: "surname", align: "center" },
      { Header: "Firstname", accessor: "firstname", align: "center" },
     // { Header: "Othername", accessor: "othername", align: "left" },
      { Header: "Email", accessor: "email", align: "center" },
      { Header: "Phone", accessor: "phone", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    rows: [
      {
        sn: (<MDTypography>1</MDTypography>),
        fileno: (<MDTypography>{ props.staffID }</MDTypography>),
        //title: (<MDTypography  color="text" fontWeight="small">{ props.titlename }</MDTypography>),
        surname: (<MDTypography  color="text" fontWeight="small">{ props.staffSurName }</MDTypography>),
        firstname:(<MDTypography color="text" fontWeight="small">{ props.staffFirstName }</MDTypography>),
       // othername:(<MDTypography  color="text" fontWeight="small">{ props.otherName }</MDTypography>),
        phone: (<MDTypography color="text" fontWeight="small">{ props.staffPhone }</MDTypography>),
        email:(<MDTypography  color="text" fontWeight="small">{props.staffEmail}</MDTypography>),
        action: (<MDTypography component="a" variant="caption" color="text"  fontWeight="small">
        <Link to='/EditProfileIndex' state={{ staffID: props.staffID}} >
        <MDButton variant="gradient" type="submit" color="success" size="sm">Edit Profile</MDButton> </Link>
    
        <Link to='/StaffProfileIndex' state={{ staffID: props.staffID}} >
        <MDButton variant="gradient" type="submit" color="info" size="sm">View Details</MDButton> </Link>
       
        {/* <MDButton className={styles.primaryBtn} onClick={() => setIsOpen(true)} color="success">
           Edit Profile
         </MDButton>
         {isOpen && <Modal setIsOpen={setIsOpen} />} */}

       </MDTypography>)    
      },
     
    ],
  };
}
