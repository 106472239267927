/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
//import MDSelect from "components/DropZone";

import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ExportToExcel} from '../Excelexport';
import TableColumns from "../TableColumns";
import DataTable from "pages/Tables/DataTable";
import { v4 as uuid } from 'uuid';
import  DateFormatter from "../DateFormatter";

function Index() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const baseUrlPosition = process.env.REACT_APP_BASE_URL_POSITION;
  const baseUrlFaculty = process.env.REACT_APP_BASE_URL_FACULTY;
  const baseUrlDepartment = process.env.REACT_APP_BASE_URL_DEPARTMENT;
  const baseUrlCategory = process.env.REACT_APP_BASE_URL_STAFFCATEGORY;
  const baseUrlState = process.env.REACT_APP_BASE_URL_STATE;
  const baseUrlStatus = process.env.REACT_APP_BASE_URL_STAFF_STATUS;
   
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  var headers   =   {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ userDetails,};

  //const [filterselectData, setFilterSelectData]= useState([]);
  const [searchResults, setSearchResults] =useState([]);
  const [results, setResults] =useState([]);
  const [data, setData] =useState([]);
  const [dataPosition, setDataPosition] =useState([]);
  const [dataCategory, setDataCategory] =useState([]);
  const [dataFaculty, setDataFaculty] =useState([]);
  const [dataDepartment, setDataDepartment] =useState([]);
  const [dataState, setDataState] =useState([]);
  const [dataStatus, setDataStatus] =useState([]);

  const [selected, setSelected] = useState();
  const fileName =uuid();
  const [selectTitleValue, setSelectTitleValue] = useState();
  const [selectGenderValue, setSelectGenderValue] = useState();
  const [selectPositionValue, setSelectPositionValue] = useState();
  const [selectCategoryValue, setSelectCategoryValue] = useState();
  const [selectFacultyValue, setSelectFacultyValue] = useState();
  const [selectDepartmentValue, setSelectDepartmentValue] = useState();
  const [selectMaritalValue, setSelectMaritalValue] = useState();
  const [selectPromotionYearValue, setSelectPromotionYearValue] = useState();
  const [selectStateValue, setSelectStateValue] = useState();
  const [selectStatusValue, setSelectStatusValue] = useState();
  const [fieldValue, setFieldValue]=useState();
  
  const gender = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
   
  ];

  const marital = [
    { value: '1', label: 'Single' },
    { value: '2', label: 'Married' },
    { value: '3', label: 'Widom' },
   
  ];
  
  let Year=[];
  const currentYear = new Date().getFullYear();
  for (var i = 1980; i <=currentYear; i++) 
  {
      Year.push({ value: i, label: i}); 
  }
  

  
  function reset(e) 
  {
    e.preventDefault();
    setSelectTitleValue('');
    setSelectGenderValue('');
    setSelectPositionValue('');
    setSelectCategoryValue('');
    setSelectFacultyValue('');
    setSelectDepartmentValue('');
    setSelectMaritalValue('');
    setSelectPromotionYearValue('');
    setSelectStateValue('');
    setSelectStatusValue('');
    setResults('')
  }
//console.log("Year: " + Year[1].value);
  const handleSubmit =(e)=>
  {
    let baseUrlSearch = process.env.REACT_APP_BASE_URL;
    e.preventDefault();
    let dat =[];
    if (typeof selectTitleValue !== "undefined" && selectTitleValue !== null)
    {      
          dat.push(["title",selectTitleValue.value ]);    
        
    }
    if (typeof selectGenderValue !== "undefined" && selectGenderValue !== null)
    {
       dat.push(["gender",selectGenderValue.value ]);  
    }
    if (typeof selectPositionValue !== "undefined" && selectPositionValue !== null)
    {
        dat.push(["position",selectPositionValue.value ]);       
    }
    if (typeof selectMaritalValue !== "undefined" && selectMaritalValue !== null)
    {
       dat.push(["marital",selectMaritalValue.value ]);     
    }  
    if (typeof selectFacultyValue !== "undefined" && selectFacultyValue !== null)
    {
        dat.push(["faculty",selectFacultyValue.value ]);     
    }  
    if (typeof selectDepartmentValue !== "undefined" && selectDepartmentValue !== null)
    {
        dat.push(["department",selectDepartmentValue.value ]);
    }
    if (typeof selectCategoryValue !== "undefined" && selectCategoryValue !== null)
    {
        dat.push(["category",selectCategoryValue.value ]);
    }
    if (typeof selectPromotionYearValue !== "undefined" && selectPromotionYearValue !== null)
    {
        dat.push(["promotionYear",selectPromotionYearValue.value ]);
        //console.log("selectPromotionYearValue : " + selectPromotionYearValue.value);
    }
    if (typeof selectStateValue !== "undefined" && selectStateValue !== null)
    {
        dat.push(["state", selectStateValue.value ]);      
    }
    if (typeof selectStatusValue !== "undefined" && selectStatusValue !== null)
    {
        dat.push(["staffstatus", selectStatusValue.value ]);      
    }


    const date = new Date();
    const formattedDate = date.toLocaleDateString('en-GB');
     // console.log(dat);
    const filterselectData = Object.fromEntries(dat);
      
     
    
      axios.post(`${baseUrl}getStaffInfoByParameter`, filterselectData, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
        {
             //console.log(response);
              if(response.statusText =='OK')
              {
               
                const customHeadings = response.data.data.map((item, i)=>({
                  "id":++i,
                  "staffID": item.staffID,
                  "title": item.titlename,
                  "surname": item.staffsurName,
                  "firstname": item.staffFirstName,
                  "email": item.staffEmail,
                  "phone": item.staffPhone,
                  "address": item.staffAddress,
                  "category":item.staffCategory,
                  "dob" : DateFormatter(item.dob),
                  "dateretirement": DateFormatter(item.dateRetirement),
                  "datefirstappointment": DateFormatter(item.dateFirstAppointment),
                  "gender": item.gendername,
                  "marital": item.maritalname,
                  "faculty": item.facultyname,
                  "department": item.departmentname,               
                  "state": item.stateoforigin,
                  "lga": item.lganame,
                  "remark": item.remark,               
                  "state": item.stateoforigin,
                  "confirmation": item.confirmation,
                  "year": item.yearofpromotion,             
                  //"guid":item.guid,
                  "position": item.currentposition,
                  "gradelevel": item.gradelevel,
                  "qualification": item.qualification,
                  "category": item.categoryname,
                  "staffstatus" :item.staffstatus,
                
                }))
                
                setSearchResults(customHeadings) 
                setResults(customHeadings) 
                toast.success('Record Match Found Successful');
                ClearControls();
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer);            
                
            }
           else
            { 
              console.log(response);      
              toast.error('Sorry!, Record Match Not Found');           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
             // window.location.reload(true);  
          }
          
        }) .catch((error) => {
          if (error.response) {
            //console.log(error.response);
            //console.log("server responded");
          } else if (error.request) {
            //console.log("network error");
          } else {
            //console.log(error);
          }
        });       
   }
 

function ClearControls(){
  setSelectTitleValue('');
  setSelectGenderValue('');
  setSelectPositionValue('');
  setSelectCategoryValue('');
  setSelectFacultyValue('');
  setSelectDepartmentValue('');
  setSelectMaritalValue('');
  setSelectPromotionYearValue('');
  setSelectStateValue('');
  setSelectStatusValue('');
}
 


   const fetchTitleData=()=>
   {         
     axios.get(`${baseUrl}getTitle`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {
             const customHeadings = response.data.data.map(item=>({
                   "value":item.titleID,
                   "label": item.name,
                  
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchPositionData=()=>
   {         
     axios.get(`${baseUrl}getPosition`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {
        
        
             
                 const customHeadings = response.data.data.map(item=>({
                   "value":item.positionID,
                   "label": item.name,
                  
                 }))
                 setDataPosition(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchCategoryData=()=>
   {         
     axios.get(`${baseUrl}getStaffcategory`,{headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {

        
         const customHeadings = response.data.data.map(item=>({
                   "value":item.staffcategoryID,
                   "label": item.name,
                  
                 }))
                 setDataCategory(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchFacultyData=()=>
   {         
     axios.get(`${baseUrl}getFaculty`,{headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {

       
         const customHeadings = response.data.data.map(item=>({
                   "value":item.facultyID,
                   "label": item.name,
                  
                 }))
                 setDataFaculty(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchDepartmentData=()=>
   {         
     axios.get(`${baseUrl}getDepartment`,{headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {

       
         const customHeadings = response.data.data.map(item=>({
                   "value":item.departmentID,
                   "label": item.name,
                  
                 }))
                 setDataDepartment(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStateData=()=>
   {         
     axios.get(`${baseUrl}getState`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {   

             
                const customHeadings = response.data.data.map(item=>({
                   "value":item.stateID,
                   "label": item.name,
                  
                 }))
                 setDataState(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStaffStatusData=()=>
   {         
     axios.get(`${baseUrl}getStaffstatus`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {   

             
                const customHeadings = response.data.data.map(item=>({
                   "value":item.staffstatusID,
                   "label": item.name,
                  
                 }))
                 setDataStatus(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
  
   //

   //console.log("State "+ dataDepartment[0].value);

   useEffect(()=>{
      fetchTitleData();
      fetchPositionData();
      fetchCategoryData();
      fetchFacultyData();
      fetchDepartmentData();
      fetchStateData();
      fetchStaffStatusData();
   },[])   


const { columns, row } = TableColumns(searchResults);
const rows =row[0]; //Reduce double array to single array

const selectTitleOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setSelectTitleValue(selectedOption);
};
const selectGenderOnChange = (selectedOption) => 
{
  setFieldValue('gender', selectedOption.value);
  setSelectGenderValue(selectedOption);
};
const selectPositionOnChange = (selectedOption) => 
{
  setFieldValue('position', selectedOption.value);
  setSelectPositionValue(selectedOption);
};
const selectCategoryOnChange = (selectedOption) => 
{
  setFieldValue('category', selectedOption.value);
  setSelectCategoryValue(selectedOption);
};
const selectFacultyOnChange = (selectedOption) => 
{
  setFieldValue('faculty', selectedOption.value);
  setSelectFacultyValue(selectedOption);
};
const selectDepartmentOnChange = (selectedOption) => 
{
  setFieldValue('department', selectedOption.value);
  setSelectDepartmentValue(selectedOption);
};
const selectMaritalOnChange = (selectedOption) => 
{
  setFieldValue('marital', selectedOption.value);
  setSelectMaritalValue(selectedOption);
};
const selectPromotionYearOnChange = (selectedOption) => 
{ 
  setFieldValue('promotion', selectedOption.value);
  setSelectPromotionYearValue(selectedOption);
};

const selectStateOnChange = (selectedOption) => 
{
  setFieldValue('state', selectedOption.value);
  setSelectStateValue(selectedOption);
};

const selectStatusOnChange = (selectedOption) => 
{
  setFieldValue('staffstatus', selectedOption.value);
  setSelectStatusValue(selectedOption);
};








  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">General Search Record</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Search Staff Information
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
                 <Grid container spacing={2}>
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={4}>              
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                  Title
                                  <Select  placeholder='Title' id="searchValueTitle" name="searchValueTitle" value={selectTitleValue} options={data} onChange={selectTitleOnChange}/>                              
                                  </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                          Gender
                                          <Select  placeholder='Gender' name="searchValueGender" value={selectGenderValue} onChange={selectGenderOnChange} options={gender}   />
                                  </MDTypography>
                            </Grid>
                            <Grid item xs={4}>
                                  <MDTypography variant="button"  color="text" fontWeight="regular">
                                                Marital Status
                                                <Select placeholder='Marital Status' name="searchValueMarital" value={selectMaritalValue} onChange={selectMaritalOnChange} options={marital}   />
                                  </MDTypography>
                            
                            </Grid>
                           <Grid item xs={4}>                 
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                      Staff Category
                                      <Select placeholder='Cateory' name="searchValueCategory" value={selectCategoryValue} onChange={selectCategoryOnChange} options={dataCategory}   />
                              </MDTypography>  
                            </Grid>     
              
                    
                           <Grid item xs={4}>     
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                        Position
                                  <Select placeholder='Position' name="searchValuePosition" value={selectPositionValue} onChange={selectPositionOnChange} options={dataPosition}   />
                                </MDTypography>  
                              </Grid>  
                           
                          <Grid item xs={4}>        
                            <MDTypography variant="button"  color="text" fontWeight="regular">
                                  Faculty
                                  <Select placeholder='Faculty' name="searchValueFaculty" value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty}   />

                            </MDTypography>
                          </Grid>
                          <Grid item xs={4}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    Department
                                    <Select placeholder='Department' name="searchValueDepartment" value={selectDepartmentValue} onChange={selectDepartmentOnChange} options={dataDepartment}   />

                              </MDTypography>
                            </Grid>
                            <Grid item xs={4}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    Year of Promotion
                                    <Select placeholder='Promotion' name="searchValuePromotion" value={selectPromotionYearValue} onChange={selectPromotionYearOnChange} options={Year}   />

                              </MDTypography>
                            </Grid>
                            <Grid item xs={4}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                  State Of Origin
                                    <Select placeholder='State' name="searchValueState" value={selectStateValue} onChange={selectStateOnChange} options={dataState}   />
                              </MDTypography>
                            </Grid>
                            <Grid item xs={4}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                 Staff Status/Category
                                    <Select placeholder='Staff Status' name="searchValueStatus" value={selectStatusValue} onChange={selectStatusOnChange} options={dataStatus}   />
                              </MDTypography>
                            </Grid>
                         </Grid>
                       
                             <MDBox mt={4} mb={5}> 
                               <Grid container spacing={3}>   
                                 <Grid item xs={2}>                        
                                      <MDButton variant="gradient" type="submit"  color="warning" size="sm">Search</MDButton>
                                  </Grid>
                                  <Grid item xs={2}>     
                                     <MDButton variant="gradient"  onClick={reset}  color="success" size="sm">Reset</MDButton>
                                  </Grid>
                                  {/* <Grid item xs={2}>     
                                      {searchResults.length!=0 && <ExportToExcel apiData={searchResults} fileName={fileName} /> } 
                                  </Grid> */}
                                  <Grid item xs={4}>     
                                      {results.length!=0 && <MDButton variant="gradient" type="submit"  color="info" size="sm">
                                        <DownloadExcel
                                          data={results}
                                          invisible={false}
                                          fileName={fileName}
                                       /></MDButton>} 
                                  </Grid>
                              </Grid>
                             </MDBox>
                     </MDBox>  
             
                  </Grid>
                          
                </Grid>
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {results.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                 mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Staff Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
