import React, { ChangeEvent, useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link } from 'react-router-dom';
import Modal from "../LeaveApplication/Modal";
import styles from "../../Reports/Modal.module.css";
import SessionName from "../../Lists/Session";
export default function data(props)
 {
    const DATA = props; 
   
    const [isOpen, setIsOpen] = useState(false);
    const description="Leave Application";
  return {
    columns: [
        { Header: "SN", accessor: "sn", width: "5%", align: "left" },
        { Header: "FileNo", accessor: "fileno", align: "left" },
        { Header: "Days", accessor: "days", align: "left" },
        { Header: "Session", accessor: "session", align: "left" },
        { Header: "Approval", accessor: "ishead", align: "left" },
        { Header: "HRM Approval", accessor: "isregistrar", align: "left" },
        { Header: "Date", accessor: "createdAt", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
      ],
  
      row: [

         DATA.map((item, i)=>(
         {             
            sn:     (++i),
            fileno: (item.staffID),
            days: (item.days),
            session: (item.session),
            ishead: (item.ishead),
            isregistrar: (item.isregistrar),
            createdAt: (item.createdAt),
           
            action: (<MDTypography component="a" variant="caption" color="text"  fontWeight="medium">
                          <MDButton className={styles.primaryBtn}  color="error" size="sm" onClick={() => setIsOpen(true)}>Delete</MDButton>
                          {isOpen && <Modal setIsOpen={setIsOpen} description={description} uid={item.id} setTitle={item.staffID} />}
                    </MDTypography>)        
         }))          
      ],
   
  };


  <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Text in a modal
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </Typography>
  </Box>
</Modal>
}