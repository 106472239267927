// @src/components/Modal.jsx

import React from "react";
import styles from "../../Reports/Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const Modal = ({ setIsOpen, description, uid, setTitle }) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const handleSubmit=(e)=>
    {
       
      e.preventDefault();   
         //console.log(userData);
         axios.post(`${baseUrl}DeleteUser/${uid}`).then(response => 
          {
              
              if(response.data.statusCode === 0)
                {                               
                  setIsOpen(false);
                  toast.success(title + ' Deleted Successfully');
                  const timer = setTimeout(() => 1000);
                  return () => clearTimeout(timer);            
                  
              }
             else
              { 
                sessionStorage.clear();            
                toast.error(response.data.message);           
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer)   
               // window.location.reload(true);  
            }
            
          }) .catch((error) => {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log(error);
            }
          });       
     }
   

  return (
    <>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>{description}</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <MDBox component="form" role="form" onSubmit={handleSubmit} >
              <div className={styles.modalContent}>
                Are you sure you want to delete the item named {setTitle} with ID {uid}?
              </div>
              <div className={styles.modalActions}>
                <div className={styles.actionsContainer}>
                        {/* <button className={styles.deleteBtn} onClick={() => setIsOpen(false)}>
                            Delete
                        </button> */}

                          <MDTypography variant="button"  color="text" fontWeight="small">
                                            <MDButton variant="gradient" type="submit"  color="error" size="sm">Delete</MDButton>
                                        </MDTypography>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
          </MDBox>
        </div>
      </div>
    </>
  );
};

export default Modal;