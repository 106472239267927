/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "../Reports/AdminLeaveApprovalTableColumns";;
import { useNavigate } from "react-router-dom";
import  DateFormatter from "../../Reports/DateFormatter";
import DataTable from "pages/Tables/DataTable";
import SessionName from "../../Lists/Session";
function Index() 
{
  const baseUrl      = process.env.REACT_APP_BASE_STAFF_LEAVEAPP;
  const [userinfo, setUserInfo] =useState([]);
  const [userinfo2, setUserInfo2] =useState([]);
  const [data, setData] =useState([]);
  const ref = useRef(null);
  const onClear = () => {
    ref.current.value = "";
  };
  const createdBy = sessionStorage.getItem("createdBy");

   const fetchLeaveData=()=>
   {       
       const staffstatusID=1
    axios.get(`${baseUrl}GetAllLeaveApplicationsByStaffStatus/${staffstatusID}`).then(response => 
     {
        //console.log(data);
        setData(response.data);
        //console.log(data);
        const customHeadings = data
                               .filter(data=>data.isregistrar===false)
                               .filter(data=>data.ishead===true).map(item=>({
                    "id": item.id,
                    "days": item.days,
                    "purpose": item.purpose,
                    "ishead":  item.ishead,
                    "isapproved": item.isapproved,
                    "isregistrar": item.isregistrar,
                    "session":  item.session,
                    "staffID": item.staffID,
                    "createdAt":DateFormatter(item.createdAt)              
                 }))
                 setUserInfo(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }


   useEffect(()=>{
    fetchLeaveData();
   },[userinfo])   


const { columns, row } = TableColumns(userinfo);
const rows =row[0]; //Reduce double array to single array



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
 


    {userinfo.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Administrator Leave Application Information(Pending Approval)  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }

{/* {userinfo2.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Leave Application Information(Pending Approval)  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
 */}

    </DashboardLayout>
  );
}

export default Index;
