/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDInput from "components/MDInput";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import MDButton from "components/MDButton";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import  DateFormatter from "../../../Reports/DateFormatter";
// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import ProfileInfoCard from "pages/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "pages/Lists/ProfilesList";
import DefaultProjectCard from "pages/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/registry/directory/Updates/EditProfile/components/Header";
import PlatformSettings from "layouts/registry/directory/Updates/EditProfile/components/PlatformSettings";
import axios from "axios";
import { useLocation } from 'react-router-dom';
// Data
import profilesListData from "layouts/registry/directory/Updates/EditProfile/data/profilesListData";

import data from "../../../Reports/StaffDataSearch/SearchResult";
import Modal from "./Modal";
import styles from "./ModalApp.module.css";
import { Label } from "@mui/icons-material";
function Overview() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  var headers   = {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ userDetails,};

  const [searchResults, setSearchResults] = useState([]);
  
  const location = useLocation();
  console.log(location.state.staffID);
 const staffID = location.state.staffID;
 const [isOpen, setIsOpen] = useState(false);
 const [surname, setSurname] = useState();
 const [firstname, setFirstName] = useState();
 const [othername, setOthername] = useState();
 const [email, setEmail] = useState();
 const [phone, setPhone] = useState();
 const [isOpen1, setIsOpen1] = useState(false);
 const [isOpenPhone, setIsOpenPhone] = useState(false);
 const [isOpenEmail, setIsOpenEmail] = useState(false);
 const [isOpenAddress, setIsOpenAddress] = useState(false);
 const [isOpenDOB, setIsOpenDOB] = useState(false);
 const fetchData=()=>
 {

  const userData = {
    staffID: staffID
  };
    axios.post(`${baseUrl}getStaffData`, userData, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
    {
        
          if(response.data.statusCode === 0)
          {
            
            //sessionStorage.setItem('staffID', response.data.data.staffID);
            setSearchResults(response.data.data[0]) 
         }
        if(response.data.statusCode === 1)
        { 
        
          toast.error(response.data.message);
          window.location.reload(true);
          const timer = setTimeout(() => 5000);
          return () => clearTimeout(timer)     
         }
      
    }) .catch((error) => {
      if (error.response) {
        //console.log(error.response);
        //console.log("server responded");
      } else if (error.request) {
        //console.log("network error");
      } else {
        //console.log(error);
      }
    });

  }
useEffect(()=>{
  fetchData();
});
 
const description="Edit Staff Profile";
const handleSurnameOnChange = (event) => {
    // 👇 Get input value from "event"
    setSurname(event.target.value);
  };

  const title1="Surname";
  const title2="Othernames";
  const title3="Phone Number";
  const title4="Email Address";
  const title5="Contact Address";
  const title6="Date of Birth";
 // console.log(searchResults);

 const colSurname ="staffSurName";
 const colFirstname ="staffFirstName";
 const colPhone ="staffPhone";
 const colEmail ="staffEmail";
 const colAddress ="staffAddress";
 const colDob ="dob";
 return(
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header info={searchResults}>
        <MDBox mt={2} mb={2}>
          <Grid container spacing={1}>
           
            <Grid item xs={12} md={2} xl={6} sx={{ display: "flex" }}>
              {searchResults && <ProfileInfoCard searchResults={searchResults}
                // title={searchResults.titlename}
                // description={searchResults.surName}
                info={{                
                Data:<Grid item xs={12} md={1} xl={12}>
                     <Grid container spacing={2}>
                            <MDTypography variant="button"  color="text" fontWeight="small">Surname</MDTypography>
                               <Grid item xs={10}>                  
                                    <MDBox><MDInput type="text" value={searchResults.staffSurName || ''}  onChange={handleSurnameOnChange}   name="surname" id="surname"  fullWidth required /></MDBox>              
                                </Grid>
                                <Grid item xs={2}>                              
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                       <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpen(true)}>Edit</MDButton>
                                           {isOpen && <Modal setIsOpen={setIsOpen} description={description} uid={searchResults.staffID} title={title1} name={searchResults.staffsurName} col={colSurname} /> },
                                        </MDTypography>
                                </Grid>

                            </Grid>
                            <Grid container spacing={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">Othername</MDTypography>
                                   <Grid item xs={10}><MDBox><MDInput type="text"  value={searchResults.staffFirstName}  name="othername" id="othername"  fullWidth required />,</MDBox></Grid>
                                     <Grid item xs={2}><MDTypography variant="button"  color="text" fontWeight="small"> <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpen1(true)}>Edit</MDButton>
                                                        {isOpen1 && <Modal setIsOpen={setIsOpen1} description={description} uid={searchResults.staffID} title={title2} name={searchResults.staffFirstName} col={colFirstname} /> },
                                                      </MDTypography>
                                     </Grid>
                            </Grid>



                            <Grid container spacing={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">Phone Number</MDTypography>
                                   <Grid item xs={10}><MDBox><MDInput type="text"  value={searchResults.staffPhone}  name="staffPhone" id="staffPhone"  fullWidth required />,</MDBox></Grid>
                                     <Grid item xs={2}><MDTypography variant="button"  color="text" fontWeight="small">   <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpenPhone(true)}>Edit</MDButton>
                                           {isOpenPhone && <Modal setIsOpen={setIsOpenPhone} description={description} uid={searchResults.staffID} title={title3} name={searchResults.staffPhone} col={colPhone} /> },
                                                      </MDTypography>
                                     </Grid>
                            </Grid>


                            <Grid container spacing={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">Email Address</MDTypography>
                                   <Grid item xs={10}><MDBox><MDInput type="text"  value={searchResults.staffEmail}  name="staffPhone" id="staffEmail"  fullWidth required />,</MDBox></Grid>
                                     <Grid item xs={2}><MDTypography variant="button"  color="text" fontWeight="small">   <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpenEmail(true)}>Edit</MDButton>
                                           {isOpenEmail && <Modal setIsOpen={setIsOpenEmail} description={description} uid={searchResults.staffID} title={title4} name={searchResults.staffEmail} col={colEmail} /> },
                                                      </MDTypography>
                                     </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">Contact Address</MDTypography>
                                   <Grid item xs={10}><MDBox><MDInput type="text"  value={searchResults.staffAddress}  name="staffPhone" id="staffAddress"  fullWidth required />,</MDBox></Grid>
                                     <Grid item xs={2}><MDTypography variant="button"  color="text" fontWeight="small">   <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpenAddress(true)}>Edit</MDButton>
                                           {isOpenAddress&& <Modal setIsOpen={setIsOpenAddress} description={description} uid={searchResults.staffID} title={title5} name={searchResults.staffAddress} col={colAddress} /> }
                                                      </MDTypography>
                                     </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">Date of Birth</MDTypography>
                                   <Grid item xs={10}><MDBox><MDInput type="text"  value={DateFormatter(searchResults.dob)}  name="staffPhone" id="dob"  fullWidth required />,</MDBox></Grid>
                                     <Grid item xs={2}><MDTypography variant="button"  color="text" fontWeight="small">   <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpenDOB(true)}>Edit</MDButton>
                                           {isOpenDOB&& <Modal setIsOpen={setIsOpenDOB} description={description} uid={searchResults.staffID} title={title6} name={DateFormatter(searchResults.dob)} col={colDob} /> }
                                                      </MDTypography>
                                     </Grid>
                            </Grid>
                           
                  
                         
                    </Grid>,
                      Department: searchResults.departmentname,
                      Faculty: searchResults.facultyname,
                      
                }}

                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                  action={{ route: "", tooltip: "Edit" }}
                  shadow={false}
                  
                  />
              }
               <Divider orientation="vertical" sx={{ ml: 8, mr: 1}} />
            </Grid>
           
            <Grid item xs={12} md={2} xl={6} sx={{ display: "flex" }}>
              {searchResults && <ProfileInfoCard searchResults={searchResults}
                // title={searchResults.titlename}
                // description={searchResults.surName}
                info={{                
                    //  Data:  <Grid item xs={12} md={1} xl={12}>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //             Surname:  <MDInput type="text"   id="surname"  fullWidth required />,
                    //             </MDBox>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //                 othername:<MDInput type="text" value={searchResults.staffFirstName}  name="othername" id="othername"  fullWidth required />,
                    //            </MDBox>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //                Mobile:<MDInput  type="text" value={searchResults.staffPhone}   name="phone" id="phone"  fullWidth required />,   
                    //            </MDBox>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //                Email:  <MDInput  type="text" value={searchResults.staffEmail}   name="email" id="email"  fullWidth required />,   
                    //             </MDBox>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //                Address:  <MDInput  type="text" value={searchResults.staffAddress}   name="address" id="address"  fullWidth required />,   
                    //             </MDBox>
                    //         </Grid>
                    //         <Grid item xs={12}>
                    //             <MDBox>
                    //                Date of Birth:  <MDInput  type="text" value={searchResults.staffAddress}   name="address" id="address"  fullWidth required />,   
                    //             </MDBox>
                    //         </Grid>
                    //        </Grid>,
                    //   Department: searchResults.departmentname,
                    //   Faculty: searchResults.facultyname,
                      
                }}

                social={[
                  
                ]}
                action={{ route: "", tooltip: "Edit Profile" }}
                shadow={false}/>
              }
               
            </Grid>





            <Grid item xs={12} sm={12} lg={10}>                             
                <Grid item xs={6}>
                    <MDTypography variant="button"  color="text" fontWeight="small">
                            <MDButton variant="gradient" type="submit"  color="warning" size="sm">Update Profile</MDButton>
                    </MDTypography>
                </Grid>
            </Grid>

          </Grid>
        </MDBox>
        
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
