import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link } from 'react-router-dom';
import Modal from "./Modal";
export default function data(props)
 {
    const DATA = props; 
    
  return {
    columns: [
        { Header: "SN", accessor: "sn", width: "5%", align: "left" },
        { Header: "FileNo", accessor: "fileno", align: "left" },
        { Header: "Title", accessor: "title", align: "left" },
        { Header: "Surname", accessor: "surname", align: "left" },
        { Header: "Firstname", accessor: "firstname", align: "left" },
        { Header: "Gender", accessor: "gender", align: "left" },
        { Header: "Marital", accessor: "marital", align: "left" },
        { Header: "DOB", accessor: "dob", align: "left" },
        { Header: "Email", accessor: "email", align: "left" },
        { Header: "State", accessor: "state", align: "left" },
        { Header: "Phone", accessor: "phone", align: "left" },
        { Header: "Qualification", accessor: "qualification", align: "left" },
        { Header: "Current Position", accessor: "position", align: "left" },
        { Header: "Position Year", accessor: "year", align: "left" },
        { Header: "Grade Level", accessor: "gradelevel", align: "left" },
        { Header: "Department", accessor: "department", align: "left" },
        { Header: "Faculty", accessor: "faculty", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
        
      ],
  
      row: [

         DATA.map((item, i)=>(
         {             
            sn:     (++i),
            fileno: (item.staffID),
            title: (item.title),
            surname: (item.surname),
            firstname: (item.firstname),
            gender: (item.gender),
            marital: (item.marital),
            phone: (item.phone),
            email: (item.email),
            dob: (item.dob),
            state: (item.state),
            qualification: (item.qualification),
            position: (item.position),
            gradelevel: (item.gradelevel),
            category: (item.category),
            year: (item.year),
            department: (item.department),
            faculty: (item.faculty),
            action: (<MDTypography component="a" variant="caption" color="text"  fontWeight="medium">
                          <Link to='/StaffProfileIndex' state={{ staffID: item.staffID}} >
                          <MDButton variant="gradient" type="submit" color="info" size="sm">View Details</MDButton> </Link>
                        
                     </MDTypography>)    

           
         }))          
      ],
   
  };


  <Modal
  open={open}
  onClose={handleClose}
  aria-labelledby="modal-modal-title"
  aria-describedby="modal-modal-description"
>
  <Box sx={style}>
    <Typography id="modal-modal-title" variant="h6" component="h2">
      Text in a modal
    </Typography>
    <Typography id="modal-modal-description" sx={{ mt: 2 }}>
      Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
    </Typography>
  </Box>
</Modal>
}