import React from 'react'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAlert from "components/MDAlert";
import MDButton from "components/MDButton";

export const ExportToExcel = ({ apiData, fileName }) => {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const exportToCSV = (apiData, fileName) => {
    const ws = XLSX.utils.json_to_sheet(apiData);
    ///console.log(ws);
    /* custom headers */
    //XLSX.utils.sheet_add_aoa(ws, [["StaffID", "Title","Surname", "FirstName", "Email","Phone","Category","Department","Faculty"]], { origin: "A1" });
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
               
                        <MDButton variant="gradient" type="submit" color="info" size="sm" onClick={(e) => exportToCSV(apiData, fileName)}>
                           Export
                        </MDButton>
                  
    // <button onClick={(e) => exportToCSV(apiData, fileName)}>Export</button>
  );
};