/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Tables from "layouts/tables";

import RTL from "layouts/rtl";
import Notifications from "layouts/notifications";
import Profile from "layouts/profile";
import SignIn from "layouts/authentication/sign-in";
import SignUp from "layouts/authentication/sign-up";
import BiodataUploader from "layouts/registry/Uploader/Biodata/";
import QualificationUploader from "layouts/registry/Uploader/Qualifications";
import PositionUploader from "layouts/registry/Uploader/Positions";
import SearchIndex from "layouts/registry/Reports/StaffDataSearch";
import StaffRecordIndex from "layouts/registry/Reports/StaffRecord";
import StaffProfileIndex from "layouts/registry/profile";
import GeneralSearch from "layouts/registry/Reports/GeneralSearch";
import RoleIndex from "layouts/registry/directory/Roles";
import CreateUserIndex from "layouts/registry/directory/Users";
import CreatePromotionIndex from "layouts/registry/directory/Updates/Promotion";
import GeneratePromotionList from "layouts/registry/Reports/PositionList";
import EditProfileIndex from "layouts/registry/directory/Updates/EditProfile"
import StaffConversionIndex from "layouts/registry/directory/StaffConversion";
import PersonalDataIndex from "layouts/registry/hrms/PersonalData";
import ChildrenDataIndex  from "layouts/registry/hrms/Children";
import SpouseDataIndex  from "layouts/registry/hrms/Spouse";
import PreviousJobDataIndex  from "layouts/registry/hrms/PreviousJob";
import RelationDataIndex  from "layouts/registry/hrms/Relations";
import QualificationDataIndex  from "layouts/registry/hrms/Qualifications";
import LeaveIndex    from "layouts/registry/hrms/Leave";
import LeaveApplicationIndex  from "layouts/registry/Users/LeaveApplication";
import PrivilegesIndex  from "layouts/registry/Users/Privileges";
import HeadsLeaveApprovalIndex from "layouts/registry/Users/HeadsApproval";
import AdminsLeaveApprovalIndex from "layouts/registry/Users/AdminApproval";
// @mui icons
import Icon from "@mui/material/Icon";
//import QualificationUploader from "layouts/registry/Uploader/Qualifications/";
//const userDetails = JSON.parse(localStorage.getItem('userDetails'));
//console.log(userDetails.role);
const routes = [
  {
    type: "collapse",
    name: "Dashboard",
    key: "dashboard",
    icon: <Icon fontSize="small">dashboard</Icon>,
    route: "/dashboard",
    component: <Dashboard />,
  },
  {
    type: "collapse",
    name: "Search Record",
    key: "StaffDataSearch",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/StaffDataSearch",
    component: < SearchIndex/>,
    roles: [1, 2], 
  },
  {
    type: "collapse",
    name: "Staff Record",
    key: "StaffRecord",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/StaffRecord",
    component: <StaffRecordIndex/>,
    roles: [1,2], 
  },
  {
    type: "collapse",
    name: "General Search",
    key: "GeneralSearch",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/GeneralSearch",
    component: <GeneralSearch />,
    roles: [1,2], 
  },
  {
    route: "/StaffProfileIndex",
    component: <StaffProfileIndex />,
  },
  {
    type: "collapse",
    name: "Create User",
    key: "Users",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Users",
    component: <CreateUserIndex />,
    roles: [1], 
  },

  {
    type: "collapse",
    name: "Update Staff Promotion",
    key: "Promotion",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/Promotion",
    component: <CreatePromotionIndex />,
    roles: [1,2], 
  },
  {
    type: "collapse",
    name: "Generate Staff Promotion",
    key: "PromotionList",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/PromotionList",
    component: <GeneratePromotionList />,
    roles: [1,3], 
  },
  {
    route: "/EditProfileIndex",
    component: <EditProfileIndex />,
  },
  {
    type: "collapse",
    name: "Staff Conversion",
    key: "StaffConversion",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/StaffConversion",
    component: <StaffConversionIndex />,
    roles: [1,2], 
  },
  //HRMS===============================================
  {
    type: "collapse",
    name: "Personal Data",
    key: "PersonalData",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/PersonalData",
    component: <PersonalDataIndex />,
    roles: [1,3], 
  },
  {
    type: "collapse",
    name: "Add Children",
    key: "Children",
    icon: <Icon fontSize="small">table_view</Icon>,
    route: "/ChildrenDataIndex",
    component: <ChildrenDataIndex />,
    roles: [1,3], 
  },
  {
    type: "collapse",
    name: "Add Spouse",
    key: "Spouse",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/SpouseDataIndex",
    component: <SpouseDataIndex />,
    roles: [1,3], 
  },
  {
    type: "collapse",
    name: "Add Prevous Job",
    key: "PreviousJob",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/PreviousJob",
    component: <PreviousJobDataIndex />,
    roles: [1,3], 
  },
  {
    type: "collapse",
    name: "Add Next of Kin",
    key: "Relations",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Relations",
    component: <RelationDataIndex />,
    roles: [1,3], 
  },
  {
    type: "collapse",
    name: "Add Qualification",
    key: "Qualifications",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Qualifications",
    component: <QualificationDataIndex />,
    roles: [1,3], 
  },

  {
    type: "collapse",
    name: "Leave Application",
    key: "Leave",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/LeaveApplication",
    component: <LeaveApplicationIndex />,
    roles: [1,3], 
  },

  {
    type: "collapse",
    name: "Add Privilege",
    key: "Privileges",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/Privileges",
    component: <PrivilegesIndex />,
    roles: [1], 
  },

  {
    type: "collapse",
    name: "Heads Approval",
    key: "LeaveApproval",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/LeaveApproval",
    component: <HeadsLeaveApprovalIndex />,
    roles: [1], 
  },
  {
    type: "collapse",
    name: "Admin Approval",
    key: "AdminApproval",
    icon: <Icon fontSize="small">person</Icon>,
    route: "/AdminApproval",
    component: <AdminsLeaveApprovalIndex />,
    roles: [1], 
  },
  {
  
    route: "/authentication/sign-in",
    component: <SignIn />,
  },
  {
  
    route: "/authentication/sign-up",
    component: <SignUp />,
  },
];

export default routes;
