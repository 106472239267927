// PositionList.js
import { useState, useEffect } from 'react';
import axios from 'axios';

export function PositionList() {
  const [positionList, setPositionList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  const baseUrl = process.env.REACT_APP_BASE_URL;

  const fetchData = async () => {
    try {
      const response = await axios.get(`${baseUrl}GetAllPromotion`, {
        headers: { 'Authorization': `Bearer ${userDetails.token}` }
      });

      const customHeadings = response.data.data.map(item => ({
        "value": item.positionID,
        "label": item.name,
      }));
      setPositionList(customHeadings);
      setLoading(false);
    } catch (err) {
      if (err.response) {
        console.log("Server responded with an error:", err.response);
      } else if (err.request) {
        console.log("Network error:", err.request);
      } else {
        console.log("Error:", err.message);
      }
      setError(err);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [baseUrl, userDetails.token]);

 //return { positionList, loading, error };

  return  {   positionList: [positionList] }
}
