/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";
import React, {useState, useEffect } from 'react';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import ReportsBarChart from "pages/Charts/BarCharts/ReportsBarChart";
import ReportsLineChart from "pages/Charts/LineCharts/ReportsLineChart";
import ComplexStatisticsCard from "pages/Cards/StatisticsCards/ComplexStatisticsCard";

// Data
import reportsBarChartData from "layouts/dashboard/data/reportsBarChartData";
import reportsLineChartData from "layouts/dashboard/data/reportsLineChartData";
import Cookies from 'js-cookie';
// Dashboard components
import Projects from "layouts/dashboard/components/Projects";
import OrdersOverview from "layouts/dashboard/components/OrdersOverview";
import axios from "axios";
import DateFormatter from '../registry/Reports/DateFormatter';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
// Data

function Dashboard() {

  const isAuthenticated = !!Cookies.get('auth');
  const navigate = useNavigate();
  const handleLogout = () => { 
    Cookies.remove('auth');
    navigate('/login'); 
  };

  if (!isAuthenticated) {
    navigate('/login'); 
    return null; // Return null to prevent rendering anything else
  }
  const { sales, tasks } = reportsLineChartData;
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [data, setData] =useState([]);
  let counter=0;
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  let role = sessionStorage.getItem('role');
  let unit = sessionStorage.getItem('unit');
  //console.log('Das ', role);
  const fetchDta=()=>
  {              
    axios.get(`${baseUrl}getAllStaffData`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
    {
              //console.log(response);
               const customHeadings = response.data.data.map(item=>({
                
                  "id":item.id,
                  "staffID": item.staffID,
                  "title": item.titlename,
                  "surname": item.staffsurName,
                  "firstname": item.staffFirstName,
                  "email": item.staffEmail,
                  "phone": item.staffPhone,
                  "address": item.staffAddress,
                  "category":item.staffCategory,
                  "staffcategory":item.category,
                  "dob" : DateFormatter(item.dob),
                  "dateretirement": DateFormatter(item.dateRetirement),
                  "datefirstappointment": DateFormatter(item.dateFirstAppointment),
                  "gender": item.gendername,
                  "marital": item.maritalname,
                  "faculty": item.facultyname,
                  "department": item.departmentname,
                  "state": item.stateoforigin,
                  "lga": item.lganame,
                  "remark": item.remark,
                  "confirmation": item.confirmation,
                  "staffPhoto": item.staffPhoto,
                  "guid":item.guid,
                  "position": item.currentposition,
                  "gradelevel": item.gradelevel,
                  "qualification": item.qualification,
                  "category": item.categoryname,
                  "staffstatus" :item.staffstatus,
                  "year":item.yearofpromotion,

                }))
                setData(customHeadings);

        }) .catch((error) => {
        if (error.response) {
            //console.log(error.response);
            console.log("server responded");
        } else if (error.request) {
          //  console.log("network error");
        } else {
            //console.log(error);
        }
        });
}

useEffect(()=>{
    fetchDta();
},[])

const totalStaff= data.length;
const academicStaff = (data.filter((item)=>item.staffcategory ===1)).length;
const nonAcademicStaff = (data.filter((item)=>item.staffcategory ===2)).length;
//sportList.filter((item) => item.category === selectedCategory)

  let username = sessionStorage.getItem('username');
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox py={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="dark"
                icon="weekend"
                title="Total Staff"
                count={totalStaff}
                percentage={{
                  color: "success",
                  amount: "+55%",
                  label: "than lask week",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                icon="leaderboard"
                title="Non-Academic Staff"
                count={nonAcademicStaff}
                percentage={{
                  color: "success",
                  amount: "+3%",
                  label: "than last month",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="success"
                icon="store"
                title="Academic Staff"
                count={academicStaff}
                percentage={{
                  color: "success",
                  amount: "+1%",
                  label: "than yesterday",
                }}
              />
            </MDBox>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <MDBox mb={1.5}>
              <ComplexStatisticsCard
                color="primary"
                icon="person_add"
                title="Retired Staff"
                count="+91"
                percentage={{
                  color: "success",
                  amount: "",
                  label: "Just updated",
                }}
              />
            </MDBox>
          </Grid>
        </Grid>
        <MDBox mt={4.5}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsBarChart
                  color="info"
                  title="website views"
                  description="Last Campaign Performance"
                  date="campaign sent 2 days ago"
                  chart={reportsBarChartData}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="success"
                  title="daily sales"
                  description={
                    <>
                      (<strong>+15%</strong>) increase in today sales.
                    </>
                  }
                  date="updated 4 min ago"
                  chart={sales}
                />
              </MDBox>
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <MDBox mb={3}>
                <ReportsLineChart
                  color="dark"
                  title="completed tasks"
                  description="Last Campaign Performance"
                  date="just updated"
                  chart={tasks}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <MDBox>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} lg={8}>
              <Projects />
            </Grid>
            <Grid item xs={12} md={6} lg={4}>
              <OrdersOverview />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
