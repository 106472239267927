/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import  DateFormatter from "../../Reports/DateFormatter";
import {PositionList} from '../../Lists/Positions';

function Index() 
{

 // console.log(PositionList);

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [data, setData] =useState([]);
  const [selectPromotionYearValue, setSelectPromotionYearValue] = useState();
  const [selectStaffValue, setselectStaffValue] = useState();
  const [fieldValue, setFieldValue]=useState();
  const [selectPositionValue, setSelectPositionValue] = useState();
  const [newstaffID, setNewStaffID] = useState();

  const { positionList } = PositionList();
  const dataposition = positionList[0];
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

 const headers = {headers: {'Authorization': `Bearer ${userDetails.token}` }}
  let Year=[];
  const currentYear = new Date().getFullYear();
  for (var i = 1980; i <=currentYear; i++) 
  {
      Year.push({ value: i, label: i}); 
  }
  
  const handleSubmit=(e)=>
    {
         e.preventDefault();   
         const userData =
         {
           staffPositionID : selectPositionValue.value,
           NewStaffID : e.target.newstaffID.value,
           StaffID : selectStaffValue.value,
           year : selectPromotionYearValue.value
         }


         
         axios.post(`${baseUrl}UpdateStaffConversion`, userData).then(response => 
          {
              console.log(response.data);
              if(response.data.statusCode === 0)
                {                               
                 
                  toast.success(selectStaffValue.value + ' Updated Successfully');
                  const timer = setTimeout(() => 1000);
                  return () => clearTimeout(timer);            
                  
              }
             else
              { 
                        
                toast.error(response.data.message);           
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer)   
               // window.location.reload(true);  
            }
            
          }) .catch((error) => {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log(error);
            }
          });   
          //Clear COntrols
          setSelectPositionValue('')
          setselectStaffValue('')
          setNewStaffID('')
          setSelectPromotionYearValue('')
     }
   
   const fetchUsersData=()=>
   {         
     axios.get(`${baseUrl}getAllStaffData`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
     {
        //console.log(response);
        const customHeadings = response.data.data.map(item=>({
                    "value":item.staffID,
                    "label":  item.staffID +' - '+ item.staffSurName +' '+ item.staffFirstName + ' . ' + item.titlename,  
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchDta=()=>{              
    axios.get(`${baseUrl}`).then(response => 
    {
       
                 //console.log(' DATA '+response.data[0].qualification);
            
                const customHeadings = response.data.map(item=>({
                  "id":item.id,
                  "staffID": item.staffID,
                  "title": item.titlename,
                  "surname": item.staffsurName,
                  "firstname": item.staffFirstName,
                  "email": item.staffEmail,
                  "phone": item.staffPhone,
                  "address": item.staffAddress,
                  "category":item.staffCategory,
                  "dob" : DateFormatter(item.dob),
                  "dateretirement": DateFormatter(item.dateRetirement),
                  "datefirstappointment": DateFormatter(item.dateFirstAppointment),
                  "gender": item.gendername,
                  "marital": item.maritalname,
                  "faculty": item.facultyname,
                  "department": item.departmentname,
                  "state": item.stateoforigin,
                  "lga": item.lganame,
                  "remark": item.remark,
                  "confirmation": item.confirmation,
                  "staffPhoto": item.staffPhoto,
                  "guid":item.guid,
                  "position": item.currentposition,
                  "gradelevel": item.gradelevel,
                  "qualification": item.qualification,
                  "category": item.categoryname,
                  "staffstatus" :item.staffstatus,
                  "year":item.yearofpromotion,

                }))
                setData(customHeadings);

        }) .catch((error) => {
        if (error.response) {
            console.log(error.response);
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });

       
   }
   useEffect(()=>{
  
    fetchUsersData()
   },[])   


// const { columns, row } = TableColumns(userinfo);
// const rows =row[0]; //Reduce double array to single array

const selectStaffOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectStaffValue(selectedOption);
};

const selectPositionOnChange = (selectedOption) => 
{
  setFieldValue('position', selectedOption.value);
  setSelectPositionValue(selectedOption);
};

const selectPromotionYearOnChange = (selectedOption) => 
{ 
  setFieldValue('promotion', selectedOption.value);
  setSelectPromotionYearValue(selectedOption);
};
const handleupdateValueOnChange = (event) => {
      // setNewStaffID(event.newstaffID.value);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Staff Conversion</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Conversion Staff 
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
               
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={2}>
                        <Grid container spacing={1}>
                        <Grid item xs={4}>                 
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                     <Select placeholder='Staff Details' name="searchValueStaff" value={selectStaffValue} onChange={selectStaffOnChange} options={data} required   />
                              </MDTypography>  
                            </Grid>     

                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="New File Number" value={newstaffID}  name="newstaffID" id="newstaffID" onChange={handleupdateValueOnChange} fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>
                                <MDTypography variant="button"  color="text" fontWeight="regular">
                                     <Select placeholder='Staff Position' name="searchValuePosition" value={selectPositionValue} onChange={selectPositionOnChange}   options={dataposition}  required />
                                 </MDTypography>
                            </Grid>
                            <Grid item xs={2}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                  
                                    <Select placeholder='Year' name="searchValuePromotion" value={selectPromotionYearValue} onChange={selectPromotionYearOnChange} options={Year}   />

                              </MDTypography>
                            </Grid>
                         
                          
                          
                         
                            <Grid item xs={12} sm={12} lg={10}>                             
                                <Grid item xs={6}>
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="warning" size="sm">Convert Staff</MDButton>
                                    </MDTypography>
                                </Grid>
                             </Grid>
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
              
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  



    </DashboardLayout>
  );
}

export default Index;
