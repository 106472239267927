/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom";
import {PositionList} from '../../Lists/Positions';

//console.log(StaffCategory)
function Index() 
{
  const baseUrl     = process.env.REACT_APP_BASE_URL_INFO;
  const baseUrlUser = process.env.REACT_APP_BASE_URL_USER;
  const baseUrlFacultyDepartment = process.env.REACT_APP_BASE_URL_FACULTY;
  const baseUrlTitle = process.env.REACT_APP_BASE_URL_TITLE;
  const baseUrlPosition = process.env.REACT_APP_BASE_URL_POSITION;
  const baseUrlFaculty = process.env.REACT_APP_BASE_URL_FACULTY;
  const baseUrlDepartment = process.env.REACT_APP_BASE_URL_DEPARTMENT;
  const baseUrlCategory = process.env.REACT_APP_BASE_URL_STAFFCATEGORY;
  const baseUrlState = process.env.REACT_APP_BASE_URL_STATE;
  const baseUrlStatus = process.env.REACT_APP_BASE_URL_STAFF_STATUS;
   
  //const [filterselectData, setFilterSelectData]= useState([]);
  const [searchResults, setSearchResults] =useState([]);
  const [results, setResults] =useState([]);
  const [data, setData] =useState([]);
  const [dataRoles, setRolesData] =useState([]);
  const [userinfo, setUserInfo] =useState([]);

  const [selectRoleValue, setselectRoleValue] = useState();
  const [surname, setSurname] = useState();
  const [firstname, setFirstName] = useState();
  const [othername, setOthername] = useState();
  //const [email, setEmail] = useState();
  const [phone, setPhone] = useState();
  const [password, setPassword] = useState();
  const [selectGenderValue, setSelectGenderValue] = useState();
  const [fieldValue, setFieldValue]=useState();
  let createdBy= null;
  let role = sessionStorage.getItem('role');
  let unit = sessionStorage.getItem('unit');
  let email = sessionStorage.getItem('email');
  
  //const [dataPosition, setDataPosition] =useState([]);
  const [dataCategory, setDataCategory] =useState([]);
  const [dataFaculty, setDataFaculty] =useState([]);
  const [dataState, setDataState] =useState([]);
  const [dataStatus, setDataStatus] =useState([]);
  const [dataDepartmentFaculty, setDataDepartmentFaculty]=useState([]);

  const [dataLGAState, setDataLGAState] =useState([]);
  const [selectStateValue, setSelectStateValue] = useState();
  const [selectLGAValue, setSelectLGAValue] = useState();
  const [selectCategoryValue, setSelectCategoryValue] = useState();
  const [selectMaritalValue, setSelectMaritalValue] = useState();
  const [selectTitleValue, setSelectTitleValue] = useState();
  const [selectFacultyValue, setSelectFacultyValue] = useState();
  const [selectDepartmentValue, setSelectDepartmentValue] = useState();
  const [selectStatusValue, setSelectStatusValue] = useState();
  const [selectPositionValue, setSelectPositionValue] = useState();
  const navigate = useNavigate();


  //Destructure list
  


  const gender = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
   
  ];

  const marital = [
    { value: '1', label: 'Single' },
    { value: '2', label: 'Married' },
    { value: '3', label: 'Widom' },
   
  ];

  const [file, setFile] = useState([]);
  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setFile(e.target.files[0]);
      console.log(file);
    }
  };
 const handleSubmit =(e)=>
  {
    e.preventDefault();
    if (!file) {
      return;
    }
  
   var headers   = {'Content-Type': 'multipart/form-data'};
   var imageName = "Testings";
   var formData = new FormData();
    formData.append("ImageName",imageName);;
    formData.append("passport",file); 
    e.preventDefault();

    if(role=='Admin') createdBy = sessionStorage.getItem('email');
 
    const userData ={
        title:selectTitleValue.value,
        staffCategory:selectCategoryValue.value,
        staffSurName:e.target.surname.value,
        ImageFile:file, 
        ImageName:imageName,
        staffFirstName:e.target.othernames.value,
        dob:e.target.dob.value,
        gender:selectGenderValue.value,
        faculty:selectFacultyValue.value,
        department:selectDepartmentValue.value,
        staffPhone:e.target.phone.value,
        staffAddress:e.target.address.value,
        staffEmail:e.target.email.value,
        dateFirstAppointment:e.target.dateFirstAppointment.value,
        lga:selectLGAValue.value,
        staffPhoto:e.target.passport.value,
        state:selectStateValue.value,
        gender:selectGenderValue.value,
        marital:selectMaritalValue.value,
        staffID:"102020",
        createdBy:"bennybriel@gmail.com",
        staffstatus:selectStatusValue.value,
        placeofbirth:e.target.placeofbirth.value,  
        town:e.target.town.value,
        positionID: selectPositionValue.value,
      }  
     
    
      axios.post(`${baseUrl}AddStaff`,  userData, {headers}).then(response => 
        {
            console.log(response);
            if(response.data.statusCode === 0)
              {
               
                console.log('STAFF ID', response.data.staffID);
                toast.success(response.data.message);           
                const timer = setTimeout(() => {
                  navigate('/ChildrenDataIndex',  {staffID: response.data.staffID});
                 }, 2000);
               
                return () => clearTimeout(timer)                           
            }
            else if(response.data.statusCode === 2)
            { 
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
            }
            else if(response.data.statusCode === 3)
            { 
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
            }
            else{
                       
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
             // window.location.reload(true);  
          }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
   const fetchRolesData=()=>
   {         
     axios.get(`${baseUrl}GetAllRoles`).then(response => 
     {
        
        const customHeadings = response.data.map(item=>({
             "value":item.id,
                   "label": item.name,
                  
                 }))
                 setRolesData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchUsersData=()=>
   {         
     axios.get(`${baseUrlUser}GetAllUsers`).then(response => 
     {
      
        const customHeadings = response.data.map(item=>({
                    "id": item.id,
                    "username" :item.username,
                    "phone": item.phoneNumber,
                    "lastname":  item.lastName,
                    "firstname": item.firstName,
                    "email":  item.email,
                    "staffID": item.staffID,
                    "role": item.userRole,
                    "createdBy": item.createdBy,
                    "createdAt": item.createdAt                 
                 }))
                 setUserInfo(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchTitleData=()=>
   {         
     axios.get(`${baseUrlTitle}GetAllTitles`).then(response => 
     {
        
         
             
                 const customHeadings = response.data.map(item=>({
                   "value":item.titleID,
                   "label": item.name,
                  
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }

   const fetchCategoryData=()=>
   {         
     axios.get(`${baseUrlCategory}GetAllStaffCatgeory`).then(response => 
     {

          console.log(response);
         const customHeadings = response.data.map(item=>({
                   "value":item.staffcategoryID,
                   "label": item.name,
                  
                 }))
                 setDataCategory(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchFacultyData=()=>
   {         
     axios.get(`${baseUrlFaculty}GetAllFaculty`).then(response => 
     {

       
         const customHeadings = response.data.map(item=>({
                   "value":item.facultyID,
                   "label": item.name,
                  
                 }))
                 setDataFaculty(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStateData=()=>
   {         
     axios.get(`${baseUrlState}GetAllState`).then(response => 
     {   

             
                const customHeadings = response.data.map(item=>({
                   "value":item.stateID,
                   "label": item.name,
                  
                 }))
                 setDataState(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStaffStatusData=()=>
   {         
     axios.get(`${baseUrlStatus}GetAllStaffStatus`).then(response => 
     {   

             
                const customHeadings = response.data.map(item=>({
                   "value":item.staffstatusID,
                   "label": item.name,
                  
                 }))
                 setDataStatus(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }

   useEffect(()=>{
    fetchRolesData();
    fetchUsersData();
    fetchTitleData();
    fetchCategoryData();
    fetchFacultyData();
    fetchStateData();
    
   },[])   

   const { positionList } = PositionList();
   const dataPosition = positionList[0];


const surNameOnChange = (selectedOption) => 
{
  setFieldValue('surname', selectedOption.value);
  setSurname(selectedOption);
};
const selectCategoryOnChange = (selectedOption) => 
{
  setFieldValue('category', selectedOption.value);
  setSelectCategoryValue(selectedOption);

  const categoryID = selectedOption.value;
  axios.get(`${baseUrlCategory}GetStaffStatusByCategory/${categoryID}`).then(response => 
   {              
              const customHeadings = response.data.map(item=>({
                 "value":item.staffstatusID,
                 "label": item.staffstatus,
                
               }))
               setDataStatus(customHeadings);

       }) .catch((error) => {
       if (error.response) {
          
           console.log("server responded");
       } else if (error.request) {
           console.log("network error");
       } else {
           console.log(error);
       }
       });

};
const selectStateOnChange = (selectedOption) => 
{
  setFieldValue('state', selectedOption.value);
  setSelectStateValue(selectedOption);
  const stateID = selectedOption.value;
   axios.get(`${baseUrlState}GetLGAByState/${stateID}`).then(response => 
    {              
               const customHeadings = response.data.map(item=>({
                  "value":item.lgaID,
                  "label": item.lga,
                 
                }))
                setDataLGAState(customHeadings);

        }) .catch((error) => {
        if (error.response) {
           
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });
 
 // const {  } = LGAStateData();

};
const selectLGAOnChange = (selectedOption) => 
{
  setFieldValue('state', selectedOption.value);
  setSelectLGAValue(selectedOption);
};
const selectMaritalOnChange = (selectedOption) => 
{
  setFieldValue('marital', selectedOption.value);
  setSelectMaritalValue(selectedOption);
};
const selectGenderOnChange = (selectedOption) => 
{
  setFieldValue('gender', selectedOption.value);
  setSelectGenderValue(selectedOption);
};

const selectTitleOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setSelectTitleValue(selectedOption);
};
const selectFacultyOnChange = (selectedOption) => 
{
  setFieldValue('faculty', selectedOption.value);
  setSelectFacultyValue(selectedOption);
 
  const facultyID=selectedOption.value;
  axios.get(`${baseUrlFacultyDepartment}GetDepratmentByFacultyID/${facultyID}`).then(response => 
   {              
       
              console.log(response);
              const customHeadings = response.data.map(item=>({
                 "value":item.departmentID,
                 "label": item.department,
                
               }))
               setDataDepartmentFaculty(customHeadings);

       }) .catch((error) => {
       if (error.response) {
           console.log(error.response);
           console.log("server responded");
       } else if (error.request) {
           console.log("network error");
       } else {
           console.log(error);
       }
       });
};
const selectDepartmentOnChange = (selectedOption) => 
{
  setFieldValue('department', selectedOption.value);
  setSelectDepartmentValue(selectedOption);
};
const selectStatusOnChange = (selectedOption) => 
{
  setFieldValue('staffstatus', selectedOption.value);
  setSelectStatusValue(selectedOption);
};
const selectPositionOnChange = (selectedOption) => 
{
  setFieldValue('position', selectedOption.value);
  setSelectPositionValue(selectedOption);
};


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Personal Data</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Add Personal Data
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
               
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={2}>
                        <Grid container spacing={1}>
                           <Grid item xs={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                     <Select  placeholder='Title' id="searchValueTitle" name="searchValueTitle" value={selectTitleValue} options={data} onChange={selectTitleOnChange}/>                             
                                 </MDTypography>
                            </Grid>
                            <Grid item xs={3}>
                                 <MDBox mb={1}>
                                     <MDInput type="text" label="Surname"  name="surname" id="surname"  fullWidth required />
                                 </MDBox>
                            </Grid>
                           
                            <Grid item xs={4}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Othertnames" name="othernames" id="othernames" fullWidth required />
                                </MDBox>
                            </Grid>
                           
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Phone Number" name="phone" id="phone" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Place of Birth" name="placeofbirth" id="placeofbirth" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Town" name="town" id="town" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="date" label="Date of Birth" name="dob" id="dob" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                      
                                          <Select  placeholder='Gender' name="searchValueGender" value={selectGenderValue} onChange={selectGenderOnChange} options={gender}   />
                                  </MDTypography>
                            </Grid>
                            <Grid item xs={6}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Permanent Address" name="address" id="address" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={3}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    <Select placeholder='State' name="searchValueState" value={selectStateValue} onChange={selectStateOnChange} options={dataState}   />
                              </MDTypography>
                            </Grid>
                           
                            <Grid item xs={3}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    <Select placeholder='LGA' name="searchValueLGA" value={selectLGAValue} onChange={selectLGAOnChange} options={dataLGAState}   />
                              </MDTypography>
                            </Grid>
                            <Grid item xs={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="regular">
                                                
                                                <Select placeholder='Marital Status' name="searchValueMarital" value={selectMaritalValue} onChange={selectMaritalOnChange} options={marital}   />
                                  </MDTypography>
                            
                            </Grid>
                           
                            <Grid item xs={5}>
                                <MDBox mb={1}>
                                  <MDInput type="text" label="Email Address" name="email" id="email" fullWidth required />
                                </MDBox>
                            </Grid>
                          
                            <Grid item xs={2}>                                    
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                      <Select placeholder='Staff Group' name="searchValueCategory" value={selectCategoryValue} onChange={selectCategoryOnChange} options={dataCategory}   />
                              </MDTypography>  
                            </Grid>     
                            <Grid item xs={3}>      
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                    <Select placeholder='Category' name="searchValueStatus" value={selectStatusValue} onChange={selectStatusOnChange} options={dataStatus}   />
                              </MDTypography>
                            </Grid>
                            <Grid item xs={5}>                                    
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                 <Select placeholder='Faculty' name="searchValueFaculty" value={selectFacultyValue} onChange={selectFacultyOnChange} options={dataFaculty}   />

                              </MDTypography>  
                            </Grid>     
                           
                            <Grid item xs={4}>
                                <MDTypography variant="button"  color="text" fontWeight="regular">
                                     <Select placeholder='Department' name="searchValueDepartment" value={selectDepartmentValue} onChange={selectDepartmentOnChange} options={dataDepartmentFaculty}  />
                                </MDTypography>
                            </Grid>

                            <Grid item xs={3}>     
                              <MDTypography variant="button"  color="text" fontWeight="regular">
                                  <Select placeholder='Position' name="searchValuePosition" value={selectPositionValue} onChange={selectPositionOnChange} options={dataPosition}   />
                                </MDTypography>  
                              </Grid>  
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="date" label="Date of First Appointment" name="dateFirstAppointment" id="dateFirstAppointment" fullWidth required />
                                </MDBox>
                            </Grid>
                           
                            <Grid item xs={3}>
                                <MDBox mb={1}>
                                  <MDInput type="file" label="Upload Passport (jpg,png,jpeg)" name="passport" id="passport" onChange={handleFileChange} accept='application/pdf, image/png'
                                            fullWidth required  />
                                </MDBox>
                            </Grid> 
                            <Grid item xs={12} sm={12} lg={10}>                             
                                <Grid item xs={6}>
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="warning" size="sm">Add Employee</MDButton>
                                    </MDTypography>
                                </Grid>
                             </Grid>
                        
                             </Grid>
                     </MDBox>
                  </Grid>
                 

            </MDBox> 
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    </DashboardLayout>
  );
}

export default Index;
