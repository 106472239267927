// @src/components/Modal.jsx

import React, {useState} from "react";
import styles from "./Modal.module.css";
import { RiCloseLine } from "react-icons/ri";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import axios from "axios";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import  DateFormatter from "../DateFormatter";

const Modal = ({ setIsOpen, col, description, title, uid, name }) => 
{
  const baseUrl = process.env.REACT_APP_BASE_URL_UPDATE_PROFILE;
  const [updateValue, setUpdateValue]= useState();

    const handleSubmit=(e)=>
    {
         e.preventDefault();   
         const userData =
         {
           searchValue : e.target.updateValue.value,
           searchParameter : col,  
           staffID:uid  
         }

         //console.log(userData);
         axios.post(`${baseUrl}UpdateStaffProfile`, userData).then(response => 
          {
              
              if(response.data.statusCode === 0)
                {                               
                  setIsOpen(false);
                  toast.success(title + ' Updated Successfully');
                  const timer = setTimeout(() => 1000);
                  return () => clearTimeout(timer);            
                  
              }
             else
              { 
                sessionStorage.clear();            
                toast.error(response.data.message);           
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer)   
               // window.location.reload(true);  
            }
            
          }) .catch((error) => {
            if (error.response) {
              console.log(error.response);
              console.log("server responded");
            } else if (error.request) {
              console.log("network error");
            } else {
              console.log(error);
            }
          });       
     }
   



        

    const handleupdateValueOnChange = (event) => {
    // console.log(event);
      setUpdateValue(event.updateValue.value);
    };
  return (
    <>
      <ToastContainer position="top-center"></ToastContainer>
      <div className={styles.darkBG} onClick={() => setIsOpen(false)} />
      <div className={styles.centered}>
        <div className={styles.modal}>
          <div className={styles.modalHeader}>
            <h5 className={styles.heading}>{description}</h5>
          </div>
          <button className={styles.closeBtn} onClick={() => setIsOpen(false)}>
            <RiCloseLine style={{ marginBottom: "-3px" }} />
          </button>
          <MDBox component="form" role="form" onSubmit={handleSubmit} >

              <div className={styles.modalContent}>
                    <Grid container spacing={1}>
                          <Grid item xs={12}>
                            <label>{ name }</label>
                                          <MDBox mb={1}>
                                              <MDInput type="text" label={title} onchange={handleupdateValueOnChange}  value={updateValue} name="updateValue" id="updateValue"  fullWidth required />
                                          </MDBox>
                          </Grid>               
                    </Grid>
              </div>
              <div className={styles.modalActions}>
                <div className={styles.actionsContainer}>
                        {/* <button className={styles.primaryBtn}>
                            Update 
                        </button> */}
                        <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="info" size="sm">Update</MDButton>
                                    </MDTypography>
                  <button
                    className={styles.cancelBtn}
                    onClick={() => setIsOpen(false)}
                  >
                    Cancel
                  </button>
                </div>
              </div>
         </MDBox>

        </div>
      </div>
    </>
  );
};

export default Modal;