import React, { ChangeEvent, useState, useEffect } from 'react';
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import {Link } from 'react-router-dom';
import Modal from "../Privileges/Modal";
import styles from "../../Reports/Modal.module.css";
import SessionName from "../../Lists/Session";
export default function data(props)
 {
    const DATA = props; 
   
    const [isOpen, setIsOpen] = useState(false);
    const description="Leave Approval";
  return {
    columns: [
        { Header: "SN", accessor: "sn", width: "5%", align: "left" },
        { Header: "FileNo", accessor: "staffID", align: "left" },
        { Header: "Department", accessor: "department", align: "left" },
        { Header: "Privilege", accessor: "privilege", align: "left" },
        { Header: "Date", accessor: "createdAt", align: "left" },
        { Header: "action", accessor: "action", align: "left" },
      ],
  
      row: [

         DATA.map((item, i)=>(
         {             
            sn:     (++i),
            staffID: (item.staffID),
            department: (item.department),
            privilege: (item.privilege),
            createdAt: (item.createdAt),
           
            action: (<MDTypography component="a" variant="caption" color="text"  fontWeight="medium">
                          <MDButton className={styles.primaryBtn}  color="success" size="sm" onClick={() => setIsOpen(true)}>Delete</MDButton>
                          {isOpen && <Modal setIsOpen={setIsOpen} description={description} uid={item.id} setTitle={item.staffID} />}
                    </MDTypography>)        
         }))          
      ],
   
  };

}