/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";
// @mui material components
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import { ToastContainer, toast } from 'react-toastify';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";
import React,{ useEffect, useState } from "react";
import {useLocation,useNavigate  } from 'react-router-dom';
// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";
import axios from "axios";
import 'react-toastify/dist/ReactToastify.css';
function Cover() {

  const firstNameRef = React.useRef();
  const lastNameRef = React.useRef();
  ///const history = useHistory();
  const navigate = useNavigate();
  const baseUrl = process.env.REACT_APP_BASE_URL;
   const handleSubmit=(e)=>
   {
      e.preventDefault();
     // alert("Please enter your");
     const firstName = e.target.firstname.value;  //firstNameRef.current.value
     const lastName  = e.target.lastname.value;
  var headers = 
  {
     'Accept':'application/json',
  };

    const userData = 
    {
      email: e.target.email.value,
      firstname: e.target.firstname.value,
      lastname: e.target.lastname.value,
      password:e.target.password.value,
      role: "User"
    };

    //console.log(userData);
    axios.post(`${baseUrl}register`, userData ).then((response) => {
     // console.log(response);
      if(response.status===200)
      {

        toast.success('User successfully registered');
        const timer = setTimeout(() => {
          navigate('/authentication/sign-in',  {email: e.target.email.value, guid: response.data});
        }, 5000);
        return () => clearTimeout(timer);
        
                                
      }
    })
    .catch((error) => {
        if (error.response) {
          console.log(error.response);
          console.log("server responded");
        } else if (error.request) {
          console.log("network error");
        } else {
          console.log(error);
        }
      });


   }
  return (
    
    <CoverLayout image={bgImage}>
        <ToastContainer position="top-center"></ToastContainer>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h2" fontWeight="medium" color="white" mt={1}>
            eTrack
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
           Signu Up
          </MDTypography>
        </MDBox>
       
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit}>
              <MDBox mb={2}>
                <MDInput type="text" label="Last Name" id="lastname" name="lastname" ref={lastNameRef} variant="standard" fullWidth required />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="text" label="First Name" id="firstname" name="firstname" ref={firstNameRef} variant="standard" fullWidth required />
              </MDBox> 
              <MDBox mb={2}>
                <MDInput type="email" label="Email" id="email" name="email" variant="standard" fullWidth required />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" name="password" id="password" variant="standard" fullWidth required />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Checkbox />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp;I agree the&nbsp;
                </MDTypography>
                <MDTypography
                  component="a"
                  href="#"
                  variant="button"
                  fontWeight="bold"
                  color="info"
                  textGradient
                >
                  Terms and Conditions
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" color="info" type="submit" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                <MDTypography variant="button" color="text">
                  Already have an account?{" "}
                  <MDTypography
                    component={Link}
                    to="/authentication/sign-in"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Sign In
                  </MDTypography>
                </MDTypography>
              </MDBox>
            </MDBox>
          </MDBox>
       
      </Card>

    </CoverLayout>
  );
}

export default Cover;
