/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, {useEffect, useState} from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import axios from 'axios';
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import DataTable from "pages/Tables/DataTable";
import DateFormatter from '../DateFormatter';
// Data
import TableColumns  from "../TableColumns";

function Index() {
 const baseUrl = process.env.REACT_APP_BASE_URL;
 const [data, setData] =useState([]);
 const userDetails = JSON.parse(localStorage.getItem('userDetails'));
 var headers   = {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ userDetails,};

  const fetchDta=()=>
  {              
    axios.get(`${baseUrl}getAllStaffData`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
    {
       
                 //console.log(' DATA '+response.data[0].qualification);
            
                const customHeadings = response.data.data.map(item=>({
                  "id":item.id,
                  "staffID": item.staffID,
                  "title": item.titlename,
                  "surname": item.staffSurName,
                  "firstname": item.staffFirstName,
                  "email": item.staffEmail,
                  "phone": item.staffPhone,
                  "address": item.staffAddress,
                  "category":item.staffCategory,
                  "dob" : DateFormatter(item.dob),
                  "dateretirement": DateFormatter(item.dateRetirement),
                  "datefirstappointment": DateFormatter(item.dateFirstAppointment),
                  "gender": item.gendername,
                  "marital": item.maritalname,
                  "faculty": item.facultyname,
                  "department": item.departmentname,
                  "state": item.stateoforigin,
                  "lga": item.lganame,
                  "remark": item.remark,
                  "confirmation": item.confirmation,
                  "staffPhoto": item.staffPhoto,
                  "guid":item.guid,
                  "position": item.currentposition,
                  "gradelevel": item.gradelevel,
                  "qualification": item.qualification,
                  "category": item.categoryname,
                  "staffstatus" :item.staffstatus,
                  "year":item.yearofpromotion,

                }))
                setData(customHeadings);

        }) .catch((error) => {
        if (error.response) {
            console.log(error.response);
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });

       
}

useEffect(()=>{
    fetchDta();
},[])


const { columns, row } = TableColumns(data);
const rows =row[0]; //Reduce double array to single array
 
 return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                 Staff Record
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder
                  alignContent= 'left'
                />
              </MDBox>
            </Card>
          </Grid>
       
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Index;
