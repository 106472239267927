/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "../TableColumns";
import DataTable from "pages/Tables/DataTable";
import { v4 as uuid } from 'uuid';
import  DateFormatter from "../DateFormatter";

let Year=[];
const currentYear = new Date().getFullYear();
for (var i = 1980; i <=currentYear; i++) 
{
    Year.push({ value: i, label: i}); 
}

function Index() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL_SEARCHING;
  const baseUrlGLevel = process.env.REACT_APP_BASE_URL_GRADELEVEL;
  const baseUrlStatus = process.env.REACT_APP_BASE_URL_STAFF_STATUS;
   
  const [dataStatus, setDataStatus] =useState([]);
  const [selectStatusValue, setSelectStatusValue] = useState();

  const [searchResults, setSearchResults] =useState([]);
  const [results, setResults] =useState([]);
  const [selectgradelevelValue, setSelectGradeLevelValue] =useState();
  const [datagradelevel, setdataGradeLevel] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [selectPromotionYearValue, setSelectPromotionYearValue] = useState();

  const fileName =uuid();

 const handleSubmit =(e)=>
  {
    
    e.preventDefault();
      
     const userData ={
        staffstatus: selectStatusValue.value,
        year: selectPromotionYearValue.value,
     }
     
      axios.post(`${baseUrl}GetPromotionList`, userData).then(response => 
        {
           //console.log(response);
            if(response.data.statusCode === 0)
              { 
                
                const customHeadings = response.data.data.map((item, i)=>({
                    "id":++i,
                    "staffID": item.staffID,
                    "title": item.titlename,
                    "surname": item.staffsurName,
                    "firstname": item.staffFirstName,
                    "email": item.staffEmail,
                    "phone": item.staffPhone,
                    "address": item.staffAddress,
                    "category":item.staffCategory,
                    "dob" : DateFormatter(item.dob),
                    "dateretirement": DateFormatter(item.dateRetirement),
                    "datefirstappointment": DateFormatter(item.dateFirstAppointment),
                    "gender": item.gendername,
                    "marital": item.maritalname,
                    "faculty": item.facultyname,
                    "department": item.departmentname,               
                    "state": item.stateoforigin,
                    "lga": item.lganame,
                    "remark": item.remark,               
                    "state": item.stateoforigin,
                    "confirmation": item.confirmation,
                    "year": item.yearofpromotion,                    
                    //"guid":item.guid,
                    "position": item.currentposition,
                    "gradelevel": item.gradelevel,
                    "qualification": item.qualification,
                    "category": item.categoryname,
                  
                  }));
                  setSearchResults(customHeadings) 
                  setResults(customHeadings)
                  setSelectPromotionYearValue('');
                  setSelectStatusValue('');
                 toast.success('Data Record Fetched Successfully');
                 const timer = setTimeout(() => 1000);            
                 return () =>{
                          clearTimeout(timer);
                          window.location.reload(true);  
                      }      
                                      
            }
            else
            { 
             
              
              setSelectPromotionYearValue('');
              setSelectStatusValue('');       
              toast.error(response.data.message);           
              const timer = setTimeout(() => 1000);
              return () => clearTimeout(timer)   
               // window.location.reload(true);  
             }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
   function reset(e) 
   {
     e.preventDefault();
     setSelectPromotionYearValue('');
     setSelectStatusValue('');
     //setResults('')
 
   }
   const fetchGradeLevelData=()=>
   {         
     axios.get(`${baseUrlGLevel}GetAllListGradeLevel`).then(response => 
     {
        
          //console.log(response);
             
                 const customHeadings = response.data.map(item=>({
                   "value":item.gradelevelID,
                   "label": item.name,
                  
                 }))
                 setdataGradeLevel(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStaffStatusData=()=>
   {         
     axios.get(`${baseUrlStatus}GetAllStaffStatus`).then(response => 
     {   

             
                const customHeadings = response.data.map(item=>({
                   "value":item.staffstatusID,
                   "label": item.name,
                  
                 }))
                 setDataStatus(customHeadings);
              
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }

   const selectStatusOnChange = (selectedOption) => 
   {
     setFieldValue('staffstatus', selectedOption.value);
     setSelectStatusValue(selectedOption);
   };
   
   //console.log("State "+ dataDepartment[0].value);

   useEffect(()=>{
    fetchGradeLevelData();
    fetchStaffStatusData();
    
   },[])   


const { columns, row } = TableColumns(results);
const rows =row[0]; //Reduce double array to single array
  
const selectPromotionYearOnChange = (selectedOption) => 
{
  setFieldValue('year', selectedOption.value);
  setSelectPromotionYearValue(selectedOption);
 
};
dataStatus.push({"value":0 , "label":"All"}); 
//console.log(dataStatus);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Generate Promotion List</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Generate Promotion List
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
                 <Grid container spacing={4}>
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={4}>
                              <Grid item xs={3}>              
                                  <MDBox mb={2}>
                                       <MDInput type="text" label="StaffID" name="staffID" id="staffID" fullWidth />
                                  </MDBox>
                              </Grid>
                               <Grid item xs={3}>
                                    <MDTypography variant="button"  color="text" fontWeight="regular">
                                       
                                            <Select placeholder='Staff Status' name="searchValueStatus" value={selectStatusValue} onChange={selectStatusOnChange} options={dataStatus} required   />
                                    </MDTypography>
                               </Grid>        
                                <Grid item xs={2}>      
                                        <MDTypography variant="button"  color="text" fontWeight="regular">
                                       
                                            <Select placeholder='Year' name="searchValuePromotion" value={selectPromotionYearValue} onChange={selectPromotionYearOnChange} options={Year}  required  />

                                    </MDTypography>
                              </Grid>
                            <Grid item xs={2}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                      <MDButton variant="gradient" type="submit"  color="warning" size="sm">Generate</MDButton>
                                 </MDTypography>
                            </Grid>
                                <Grid item xs={2}>     
                                     <MDButton variant="gradient"  onClick={reset}  color="success" size="sm">Reset</MDButton>
                                  </Grid>
                               <Grid item xs={4}>     
                                      {results.length!=0 && <MDButton variant="gradient" type="submit"  color="info" size="sm">
                                        <DownloadExcel
                                          data={results}
                                          invisible={false}
                                          fileName={fileName}
                                       /></MDButton>} 
                                  </Grid>
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
                </Grid>
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {results.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
       
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Staff Promotion Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
