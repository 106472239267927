/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "./QualificationsTableColumns";;
import { useNavigate } from "react-router-dom";
import  DateFormatter from "../../Reports/DateFormatter";
function Index() 
{
  const baseUrl     = process.env.REACT_APP_BASE_STAFF_QUALIFICATION;
  const baseUrlStaff = process.env.REACT_APP_BASE_URL_SEARCHING;
  const baseUrlList = process.env.REACT_APP_BASE_STAFF_QUALIFICATION_LIST;
  const [dataQualification, setDataQualification] = useState();
  const [userinfo, setUserInfo] =useState([]);
  const [data, setData] =useState([]);
  const [selectStaffValue, setselectStaffValue] = useState();
  const [selectQualficationValue,setselectQualficationsValue]=useState();
  const [fieldValue, setFieldValue]=useState();
  const navigate = useNavigate();
  const gender = [
    { value: '1', label: 'Male' },
    { value: '2', label: 'Female' },
   
  ];
  const ref = useRef(null);

  const onClear = () => {
    ref.current.value = "";
  };
  const createdBy ="bennybriel@gmail.com";
  const handleSubmit =(e)=>
  {
    e.preventDefault();
 
     window.sessionStorage.setItem('ID',selectStaffValue.value);
 
    const userData ={
        qualificationID:selectQualficationValue.value,
        staffID: selectStaffValue.value,
        createdBy:createdBy,
      }  
     
    
      axios.post(`${baseUrl}Add`, userData).then(response => 
        {
            
             if(response.data.statusCode === 0)
              {

                toast.success(response.data.message);
                const timer = setTimeout(() => 5000);
                setselectStaffValue('');
                setselectQualficationsValue('');
                return () => clearTimeout(timer);            
                
            }
           else
            { 
                        
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
             // window.location.reload(true);  
          }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
   const fetchQualificationListData=()=>
   {         
     axios.get(`${baseUrlList}GetAllQualification`).then(response => 
     {
        
        console.log(response);
             
                 const customHeadings = response.data.map(item=>({
                   "value":item.qualificationID,
                   "label": item.name,
                  
                 }))
                 setDataQualification(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchUsersData=()=>
   {       
     const ID =  window.sessionStorage.getItem('ID');
     axios.get(`${baseUrl}GetAllStaffQualificationsByStaffID`,  {staffID: ID}).then(response => 
     {
      
        const customHeadings = response.data.map(item=>({
                    "id": item.id,
                    "qualificationID": item.qualificationID,
                    "staffID": item.staffID,
                    "createdAt":DateFormatter(item.createdAt)              
                 }))
                 setUserInfo(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchStaffData=()=>
   {         
     axios.get(`${baseUrlStaff}`).then(response => 
     {
        console.log(response);
        const customHeadings = response.data.map(item=>({
                    "value":item.staffID,
                    "label":  item.staffID +' - '+ item.staffsurName +' '+ item.staffFirstName + ' . ' + item.titlename,  
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   useEffect(()=>{
    fetchQualificationListData();
    fetchStaffData();
   },[])   

   useEffect(()=>{
    fetchUsersData();
  },[])   
const { columns, row } = TableColumns(userinfo);
const rows =row[0]; //Reduce double array to single array



const selectStaffOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectStaffValue(selectedOption);
};

const selectQualificationOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectQualficationsValue(selectedOption);
};


  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Add Qualifications</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Add Qualification
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
               
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={2}>
                        <Grid container spacing={1}>
                           <Grid item xs={6}>
                                  <MDTypography variant="button"  color="text" fontWeight="regular">
                                      <Select placeholder='Staff Details' name="searchValueStaff" value={selectStaffValue} onChange={selectStaffOnChange} options={data} required   />
                                </MDTypography>  
                            </Grid>
                           
                            <Grid item xs={3}>
                                 <MDTypography variant="button"  color="text" fontWeight="small">
                                     <Select  placeholder='Qualification' id="searchValueQualification" name="searchValueQualification" value={selectQualficationValue} options={dataQualification} onChange={selectQualificationOnChange}/>                             
                                 </MDTypography>
                            </Grid>
                       
                     
                                              
                                <Grid item xs={3}>
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="warning" size="sm">Add Qualification</MDButton>
                                    </MDTypography>
                                </Grid>
                           
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
              
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {userinfo.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  User's Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
