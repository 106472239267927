/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { Link,useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Grid";
import MuiLink from "@mui/material/Link";
import Cookies from 'js-cookie';
// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import GitHubIcon from "@mui/icons-material/GitHub";
import GoogleIcon from "@mui/icons-material/Google";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";
import axios from "axios";
// Images
import bgImage from "assets/images/bg-sign-in-basic.jpeg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GoogleLogin } from '@react-oauth/google';
import { saveTokenInLocalStorage} from '../../../services/AuthService';


function Basic() {
  const [rememberMe, setRememberMe] = useState(false);
  const responseMessage = (response) => {
    console.log(response);
};
const errorMessage = (error) => {
    console.log(error);
};
  localStorage.removeItem('userDetails');
  const handleSetRememberMe = () => setRememberMe(!rememberMe);
  const baseUrl = process.env.REACT_APP_BASE_URL;
  //const baseUrl = REACT_APP_BASE_URL = "http://gracelandcarehomes.ng/api/v1/"
  const navigate = useNavigate();
  localStorage.removeItem('username');
  const handleSubmit = (e) =>{
    e.preventDefault();
     
   let userEmail= e.target.email.value;
    const userData = {
      email: e.target.email.value,
      password: e.target.password.value,
    };

       //console.log(baseUrl);
      axios.post(`${baseUrl}login`, userData ).then((response) => 
        {
         //console.log(response.data);
         if(response.data.data.statuscode===0)
         {
           sessionStorage.clear();
           saveTokenInLocalStorage(response.data.data);
          //  //console.log('Unit ', response.data.unit);
           const expirationTime = new Date(new Date().getTime() + 5 * 60000);
           Cookies.set('auth', JSON.stringify(userData), { expires: expirationTime });
           sessionStorage.setItem('username',response.data.data.name);
           sessionStorage.setItem('createdBy',e.target.email.value);
           sessionStorage.setItem('role', response.data.data.role);
           sessionStorage.setItem('staffID', response.data.data.staffID);
           sessionStorage.setItem('unit', response.data.data.unit);
           sessionStorage.setItem('email', userEmail);
        
           toast.success('Login successfully');
           const timer = setTimeout(() => {
            navigate('/dashboard',  {username: response.data.data.username, 
                                         role: response.data.data.role,
                                         unit:response.data.data.unit,
                                         email:userEmail});
           }, 2000);
           return () => clearTimeout(timer);                                    
         }
        else
          {
            toast.error("Invalid Username/Password");
            const timer = setTimeout(() => { }, 2000);
            return () => clearTimeout(timer);
          }
       })
       .catch((error) => {
           if (error.response) {
             console.log(error.response);
             console.log("server responded");
           } else if (error.request) {
             console.log("network error");
           } else {
             console.log(error);
           }
         });
    

  }
  return (
   
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="none"
            borderRadius="lg"
            coloredShadow="info"
            mx={2}
            mt={-3}
            p={2}
            mb={1}
            textAlign="center"
          >
            <ToastContainer position="top-center"></ToastContainer>
            <MDTypography variant="h4" fontWeight="bold" color="white" mt={1}>
                <span style={{ color:'red' }}>LAUTECH REGISTRY</span>
            </MDTypography>
            <MDTypography display="block" variant="button" fontWeight="bold" color="warning" my={1}>
                          Sign In
                         
            </MDTypography>
          </MDBox>
          <MDBox pt={4} pb={3} px={3}>
            <MDBox component="form" role="form" onSubmit={handleSubmit} >
              <MDBox mb={2}>
                <MDInput type="email" label="Email" name="email" id="email" fullWidth required />
              </MDBox>
              <MDBox mb={2}>
                <MDInput type="password" label="Password" name="password" id="password" fullWidth required />
              </MDBox>
              <MDBox display="flex" alignItems="center" ml={-1}>
                <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                <MDTypography
                  variant="button"
                  fontWeight="regular"
                 
                  onClick={handleSetRememberMe}
                  sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                >
                  &nbsp;&nbsp; <span style={{ color:'red' }}>Remember me</span>
                </MDTypography>
              </MDBox>
              <MDBox mt={4} mb={1}>
                <MDButton variant="gradient" type="submit" color="warning" fullWidth>
                  sign in
                </MDButton>
              </MDBox>
            
              <GoogleLogin onSuccess={responseMessage} onError={errorMessage} />
            </MDBox>

          </MDBox>
        </Card>
      </BasicLayout>
   
  );
}

export default Basic;
