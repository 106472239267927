/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { useRef, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import axios from 'axios';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "../Reports/UserLeaveTableColumns";;
import { useNavigate } from "react-router-dom";
import  DateFormatter from "../../Reports/DateFormatter";
import DataTable from "pages/Tables/DataTable";
import SessionName from "../../Lists/Session";
function Index() 
{
  const baseUrl      = process.env.REACT_APP_BASE_STAFF_LEAVEAPP;
  const baseUrlStaff = process.env.REACT_APP_BASE_URL_SEARCHING;
  const baseUrlSessionList = process.env.REACT_APP_BASE_STAFF_SESSION_LIST;
  const [userinfo, setUserInfo] =useState([]);
  const [data, setData] =useState([]);
  const [selectSessionValue, setselectSessionValue] = useState();
  const [fieldValue, setFieldValue]=useState();
  const [days, setDays] = useState();
  const ref = useRef(null);
  const onClear = () => {
    ref.current.value = "";
  };
  const createdBy = sessionStorage.getItem("createdBy");  
  const staffID = sessionStorage.getItem("staffID");
 //console.log(staffID);
 const handleSubmit =(e)=>
  {
    e.preventDefault();
    const userData ={
        session:selectSessionValue.value,
        days: e.target.days.value,
        createdBy:createdBy,
        staffID:staffID,
        purpose: e.target.purpose.value,
      }  
      axios.post(`${baseUrl}Add`, userData).then(response => 
        {
            if(response.data.statusCode === 0)
              {
                toast.success(response.data.message);
                const timer = setTimeout(() => 5000);
                selectSessionValue('');
                return () => clearTimeout(timer);            
                
            }
           else
            { 
                    
              toast.error(response.data.message);           
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)   
             // window.location.reload(true);  
          }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
 

   const fetchUsersData=()=>
   {       
    const userData ={ staffID: staffID, session:0 };
     axios.post(`${baseUrl}GetLeaveByStaffID`, userData).then(response => 
     {
        //console.log(response);
        const customHeadings = response.data.map(item=>({
                    "id": item.id,
                    "days": item.days,
                    "purpose": item.purpose,
                    "ishead":  item.ishead,
                    "isapproved": item.isapproved,
                    "isregistrar": item.isregistrar,
                    "session":  item.session,
                    "staffID": item.staffID,
                    "createdAt":DateFormatter(item.createdAt)              
                 }))
                 setUserInfo(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   const fetchSessionData=()=>
   {         
     axios.get(`${baseUrlSessionList}GetAllSessions`).then(response => 
     {
       // console.log(response);
        const customHeadings = response.data.map(item=>({
                    "value":item.sessionID,
                    "label":  item.name, 
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }
   useEffect(()=>{
    fetchUsersData();
    fetchSessionData();
   },[data])   


const { columns, row } = TableColumns(userinfo);
const rows =row[0]; //Reduce double array to single array



const selectSessionOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setselectSessionValue(selectedOption);

   const sessionID = selectedOption.value;
   const userData ={ staffID: staffID, session:sessionID };
   axios.post(`${baseUrl}GetLeaveDays`, userData).then(response => 
    {              
              
              if(response.data.statusCode === 0)
              {
                 toast.success(response.data.message);
                  setDays(response.data.day);
              }
             else
             {
                toast.error(response.data.message);           
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer)   
             }
        }) .catch((error) => {
        if (error.response) {
           
            console.log("server responded");
        } else if (error.request) {
            console.log("network error");
        } else {
            console.log(error);
        }
        });




};

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={6}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Leave Application</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Leave Application
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
               
                  
                  <Grid item xs={12} sm={8} lg={12}>                
                    <MDBox pt={2} pb={7} px={2}>
                        <Grid container spacing={1}>
                           <Grid item xs={5}>
                                  <MDTypography variant="button"  color="text" fontWeight="regular">
                                  
                                      <Select placeholder='Academic Session' name="searchValueSession" value={selectSessionValue} onChange={selectSessionOnChange} options={data} required   />
                                </MDTypography>  
                            </Grid>
                           
                           {days && days> 0 && 
                                <Grid item xs={2}>
                                    <MDBox mb={1}>
                                        <MDInput type="text" label="Days"  name="days" id="days"  fullWidth required />
                                    </MDBox>
                                </Grid>

                       
                            }
                           

                           <Grid item xs={12}>
                            <MDBox mb={1}>
                                <MDInput type="text" label="Purpose"  name="purpose" id="purpose"  fullWidth required />
                            </MDBox>
                            </Grid>
                           {days && days> 0 &&                
                                <Grid item xs={5}>
                                    <MDTypography variant="button"  color="text" fontWeight="small">
                                        <MDButton variant="gradient" type="submit"  color="warning" size="sm">Apply Now</MDButton>
                                    </MDTypography>
                                </Grid>
                            }
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
              
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {userinfo.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Leave Application Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
