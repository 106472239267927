/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import TableColumns from "./RolesTableColumns";
import DataTable from "pages/Tables/DataTable";

function Index() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [searchResults, setSearchResults] =useState([]);
  const [results, setResults] =useState([]);
  const [data, setData] =useState([]);
  const [fieldValue, setFieldValue]=useState();


 const handleSubmit =(e)=>
  {
    
    e.preventDefault();
      
     const userData ={
        Name: e.target.roleName.value,
     }
     
      axios.post(`${baseUrl}AddUserRoles`, userData).then(response => 
        {
           
            if(response.data.statusCode === 0)
              {    
                 toast.success('Role Created Successfully');
                 const timer = setTimeout(() => 1000);            
                 return () =>{
                          clearTimeout(timer);
                          window.location.reload(true);  
                      }      
                                      
            }
            else
            { 
                 
              toast.error(response.data.message);           
              const timer = setTimeout(() => 1000);
              return () => clearTimeout(timer)   
               // window.location.reload(true);  
             }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
   const fetchRoleData=()=>
   {         
     axios.get(`${baseUrl}GetAllRoles`).then(response => 
     {
        
         ///console.log(response);
             
                 const customHeadings = response.data.map(item=>({
                   "id":item.id,
                   "name": item.name,

                  
                 }))
                 setData(customHeadings);
 
         }) .catch((error) => {
         if (error.response) {
             console.log(error.response);
             console.log("server responded");
         } else if (error.request) {
             console.log("network error");
         } else {
             console.log(error);
         }
         });
   }


   //console.log("State "+ dataDepartment[0].value);

   useEffect(()=>{
    fetchRoleData();
   },[data])   


const { columns, row } = TableColumns(data);
const rows =row[0]; //Reduce double array to single array

const selectTitleOnChange = (selectedOption) => 
{
  setFieldValue('title', selectedOption.value);
  setSelectTitleValue(selectedOption);
};



  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={7}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Create User Roles</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Add Roles
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
                 <Grid container spacing={4}>
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={4}>
                            <Grid item xs={12} sm={5} lg={1}>
                    
                            </Grid>
                            <Grid item xs={7}>              
                               <MDBox mb={4}>
                                  <MDInput type="text" label="Role Title" name="roleName" id="roleName" fullWidth required />
                                </MDBox>
                            </Grid>
                            <Grid item xs={4}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                      <MDButton variant="gradient" type="submit"  color="warning" size="sm">Add Role</MDButton>
                                 </MDTypography>
                            </Grid>
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
                </Grid>
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {data.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Role Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
