/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import MDInput from "components/MDInput";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";


import Select from 'react-select'
import { DownloadExcel } from "react-excel-export";
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import TableColumns from "../RolesTableColumns";import DataTable from "pages/Tables/DataTable";
import { v4 as uuid } from 'uuid';


let Year=[];
const currentYear = new Date().getFullYear();
for (var i = 1980; i <=currentYear; i++) 
{
    Year.push({ value: i, label: i}); 
}

function Index() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  // const baseUrlGLevel = process.env.REACT_APP_BASE_URL;
  // const baseUrlAddGrade = process.env.REACT_APP_BASE_URL_ADDGRADE
  // const baseUrlPosition = process.env.REACT_APP_BASE_URL_POSITION;
  const [searchResults, setSearchResults] =useState([]);
  const [confirmation, setConfirmation] =useState();
  const [selectgradelevelValue, setSelectGradeLevelValue] =useState();
  const [datagradelevel, setdataGradeLevel] =useState([]);
  const [fieldValue, setFieldValue]=useState();
  const [selectPromotionYearValue, setSelectPromotionYearValue] = useState();
  const [valiatestaffID, setValiateStaffID] =useState([]);
  const [selectPositionValue, setSelectPositionValue] = useState();
  const [dataPosition, setDataPosition] =useState([]);
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

 const headers = {headers: {'Authorization': `Bearer ${userDetails.token}` }}
 const handleSubmit =(e)=>
  {
    
    e.preventDefault();
      
     const userData ={
        staffID: e.target.staffID.value,
        positionID : selectPositionValue,
        year: selectPromotionYearValue.value,
        createdBy: userDetails.email,

     }
     
      axios.post(`${baseUrl}AddPosition`, userData, headers).then(response => 
        {
           
            if(response.data.statusCode === 0)
              {    
                 toast.success(response.data.message);
                 const timer = setTimeout(() => 1000);            
                 return () =>{
                          clearTimeout(timer);
                          window.location.reload(true);  
                      }      
                                      
            }
            else
            { 
                      
              toast.error(response.data.message);           
              const timer = setTimeout(() => 1000);
              return () => clearTimeout(timer)   
               // window.location.reload(true);  
             }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });       
   }
 
  //  const fetchGradeLevelData=()=>
  //  {         
  //    axios.get(`${baseUrlGLevel}GetAllListGradeLevel`).then(response => 
  //    {
        
  //         //console.log(response);
             
  //                const customHeadings = response.data.map(item=>({
  //                  "value":item.gradelevelID,
  //                  "label": item.name,
                  
  //                }))
  //                setdataGradeLevel(customHeadings);
 
  //        }) .catch((error) => {
  //        if (error.response) {
  //            console.log(error.response);
  //            console.log("server responded");
  //        } else if (error.request) {
  //            console.log("network error");
  //        } else {
  //            console.log(error);
  //        }
  //        });
  //  }


   //console.log("State "+ dataDepartment[0].value);

   useEffect(()=>{
    fetchPositionData();
   },[datagradelevel])   


const selectPromotionYearOnChange = (selectedOption) => 
{
  setFieldValue('year', selectedOption.value);
  setSelectPromotionYearValue(selectedOption);
};

const handleDivBlur = event => {
  event.preventDefault();
  const userData = {staffID: event.target.value};
  axios.post(`${baseUrl}getStaffData`, userData,  headers).then(response => 
    {
          
          if(response.data.statusCode === 0)
          {
            const name = response.data.data[0].titlename+ ' ' +response.data.data[0].staffFirstName + ' ' +  response.data.data[0].staffSurName
            setValiateStaffID(name) 
            setConfirmation(response.data.data[0].confirmation)
            toast.success(name);
            const timer = setTimeout(() => 5000);
            return () => clearTimeout(timer);            
            
        }
        if(response.data.statusCode === 1)
        { 
          setValiateStaffID('');
          toast.error(response.data.message);
          const timer = setTimeout(() => 5000);
          return () => clearTimeout(timer)     
          window.location.reload(true);
      }
      
    }) .catch((error) => {
      if (error.response) {
        console.log(error.response);
        console.log("server responded");
      } else if (error.request) {
        console.log("network error");
      } else {
        console.log(error);
      }
    });
};
const handleValidateStaffID=(e)=>
{
   
}

const fetchPositionData=()=>
{         
  axios.get(`${baseUrl}GetAllPromotion`, {headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
  {
              const customHeadings = response.data.data.map(item=>({
                "value":item.positionID,
                "label": item.name,
               
              }))
              setDataPosition(customHeadings);

      }) .catch((error) => {
      if (error.response) {
          console.log(error.response);
          console.log("server responded");
      } else if (error.request) {
          console.log("network error");
      } else {
          console.log(error);
      }
      });
}
const selectPositionOnChange = (selectedOption) => 
{
  setFieldValue('position', selectedOption.value);
  //console.log(selectedOption.value);
  setSelectPositionValue(selectedOption.value);
};
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={5} justifyContent="center">
          <Grid item xs={12} lg={10}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Update Staff Promotion</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Update Staff Promotion
                </MDTypography>
              </MDBox>
              <MDBox component="form" role="form" onSubmit={handleSubmit} >
                 <Grid container spacing={1}>
                  
                 {valiatestaffID   && <Grid item  lg={12}>
                      <MDBox pt={0} pb={0} px={12}  style={{ color:'red'}}>
                        {valiatestaffID} 
                      </MDBox>
                  </Grid>
                 }
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={4}>
                         
                               <Grid item xs={12} sm={3} lg={3}>
                              
                                    <MDBox mb={3}>
                                        <MDInput type="text" label="File Number" onBlur={handleDivBlur} name="staffID" id="staffID" fullWidth required />
                                    </MDBox>
                               </Grid>
                            <Grid item xs={3}>              
                                    <MDTypography variant="button"  color="text" fontWeight="regular">
                                         <Select placeholder='Position' name="searchValuePosition" value={selectPositionValue} onChange={selectPositionOnChange} options={dataPosition}   />
                                    </MDTypography>
                                </Grid>
                                <Grid item xs={3}>      
                                        <MDTypography variant="button"  color="text" fontWeight="regular">
                                           
                                            <Select placeholder='Year' name="searchValuePromotion" value={selectPromotionYearValue} onChange={selectPromotionYearOnChange} options={Year}   />

                                    </MDTypography>
                              </Grid>
                            <Grid item xs={3}>
                           
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                  {valiatestaffID &&
                                      <MDButton variant="gradient" type="submit"  color="warning" size="sm">Update</MDButton>
                                    }
                                      </MDTypography>
                           
                            </Grid>
                         </Grid>
                     </MDBox>  
             
                  </Grid>
                          
                </Grid>
              </MDBox>
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {/* {data.length!=0 &&
      <MDBox pt={6} pb={3}>
        <Grid container spacing={6}>
        <Grid item xs={3}></Grid>
          <Grid item xs={6}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Role Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={3}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    } */}
    </DashboardLayout>
  );
}

export default Index;
