/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React, { ChangeEvent, useState, useEffect, useLocation } from 'react';
// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Select from 'react-select'
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDInput from "components/MDInput";
import Cookies from 'js-cookie';
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {ExportToExcel} from '../Excelexport';
import SearrchResult from "./SearchResult";
import DataTable from "pages/Tables/DataTable";
import DateFormatter from '../DateFormatter';


function Index() {
  const baseUrl =    process.env.REACT_APP_BASE_URL;
  const baseHeader = process.env.REACT_APP_BASE_URL;
  const [searchResults, setSearchResults] =useState([]);
  const [data, setData] =useState([]);
  const [dataParameter, setDataParameter] =useState([]);
  const [selectParameterValue, setSelectParameterValue] = useState();
  const userInfo = sessionStorage.getItem('userData');
  const [fieldValue, setFieldValue]=useState();
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  var headers   = {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ userDetails,};

  const handleSubmit =(e)=>
  {
    e.preventDefault();
     const userData = 
     {
      searchParameter: selectParameterValue.value,
      searchValue :    e.target.searchValue.value
     };
      axios.post(`${baseUrl}StaffDataSearch`, userData,{headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
        {
              //console.log(response.data.message);
              if(response.data.statuscode === 0)
              {      
                setSearchResults(response.data.data[0]) 
                toast.success('Record Match Found Successful');
                const timer = setTimeout(() => 5000);
                return () => clearTimeout(timer);            
            }
            else
            { 
                      
              toast.error('No Record Found');
              const timer = setTimeout(() => 5000);
              return () => clearTimeout(timer)     
          }
          
        }) .catch((error) => {
          if (error.response) {
            console.log(error.response);
            console.log("server responded");
          } else if (error.request) {
            console.log("network error");
          } else {
            console.log(error);
          }
        });

        
  }
  
  const { columns, rows } = SearrchResult(searchResults);
  const fileName =searchResults.staffID;
   
 
  const userData = {
    staffID: searchResults.staffID,
  };



  useEffect(()=>{
  axios.post(`${baseUrl}getStaffData`, userData, {headers: {'Authorization': `Bearer ${userDetails.token}` }} ).then(response => 
    {                     
             // console.log(response);
              const customHeadings = response.data.data.map(item=>({
              "id":item.id,
              "staffID": item.staffID,
              "title": item.titlename,
              "surname": item.staffsurName,
              "firstname": item.staffFirstName,
              "email": item.staffEmail,
              "phone": item.staffPhone,
              "address": item.staffAddress,
              "category":item.staffCategory,
              "dob" : DateFormatter(item.dob),
              "dateretirement": DateFormatter(item.dateRetirement),
              "datefirstappointment": DateFormatter(item.dateFirstAppointment),
              "gender": item.gender,
              "marital": item.maritalname,
              "faulty": item.faulty,
              "department": item.department,
              "state": item.stateoforigin,
              "lga": item.lganame,
              "remark": item.remark,
              "confirmation": item.confirmation,
              "staffPhoto": item.staffPhoto,
              "guid":item.guid,
              "position": item.currentposition,
              "gradelevel": item.gradelevel,
              "qualification": item.qualification,
              "category": item.categoryname,
              "staffstatus" :item.staffstatus,
              
            }))
            ///const result = Object.values(customHeadings);
            setData(customHeadings);
    }) .catch((error) => {
      if (error.response) {
       // console.log(error.response);
        //console.log("server responded");
      } else if (error.request) {
        //console.log("network error");
      } else {
//console.log(error);
      }
    });

  },[])

    const parameters = [
      { value: 'staffEmail',    label: 'staffEmail' },
      { value: 'staffID',       label: 'staffID' },
      { value: 'staffPhone',    label: 'staffPhone' },
      { value: 'staffSurName',  label: 'staffSurName' },
     
    ];


 const selectParameterOnChange = (selectedOption) => 
  {
    setFieldValue('parameter', selectedOption.value);
    setSelectParameterValue(selectedOption);
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <ToastContainer position="top-center"></ToastContainer>
      <MDBox mt={6} mb={3}>
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={12} lg={8}>
            <Card>
              <MDBox p={2} lineHeight={0}>
                <MDTypography variant="h5"  color="warning">Search Record</MDTypography>
                <MDTypography variant="button"  color="text" fontWeight="regular">
                 To Search Staff Information
                </MDTypography>
              </MDBox>
              <MDBox p={2}>
                <Grid container spacing={5}>
                  
                  <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
  

                    <MDBox component="form" role="form" onSubmit={handleSubmit} >
                    <Grid container spacing={2}>

                   <Grid item xs={12} sm={12} lg={12}>                
                    <MDBox pt={4} pb={3} px={3}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>              
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                  <Select  placeholder='Select Parameter' id="searchParameterValue" onChange={selectParameterOnChange} name="searchParameterValue" options={parameters} required />                              
                                  </MDTypography>
                            </Grid>
                            <Grid item xs={6}>
                                  <MDTypography variant="button"  color="text" fontWeight="small">
                                  <MDInput type="text" label="File Number/Email/Phone No." name="searchValue" id="searchValue" fullWidth required />                                  </MDTypography>
                            </Grid>
                          <Grid item xs={3}>
                             
                                <MDButton variant="gradient" type="submit" color="warning" size="sm">Search Now</MDButton>                                             
                         </Grid>
                            {searchResults.length!=0 && <ExportToExcel apiData={data} fileName={fileName} /> } 
                        </Grid>  
                           
                     </MDBox>           
                  </Grid>
                     
                </Grid>
              </MDBox>

              </MDBox>
                  </Grid>
                          
                </Grid>
              </MDBox>  
                          
            </Card>
          </Grid>
        </Grid>
      </MDBox>
  


    {searchResults.length!=0 &&
      <MDBox pt={2} pb={3}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Card>
              <MDBox
                mx={2}
                mt={-3}
                py={3}
                px={2}
                variant="gradient"
                bgColor="warning"
                borderRadius="lg"
                coloredShadow="info"
              >
                <MDTypography variant="h6" color="white">
                  Staff Information  
                </MDTypography>
              </MDBox>
              <MDBox pt={2}>
                <DataTable
                  table={{ columns, rows }}
                  isSorted={true}
                  entriesPerPage={true}
                  showTotalEntries={true}
                  noEndBorder={false}                 
                />
              </MDBox>
            </Card>
          </Grid>
        
        </Grid>
      </MDBox>
    }
    </DashboardLayout>
  );
}

export default Index;
