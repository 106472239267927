/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 React example components
import DashboardLayout from "pages/LayoutContainers/DashboardLayout";
import DashboardNavbar from "pages/Navbars/DashboardNavbar";
import Footer from "pages/Footer";
import ProfileInfoCard from "pages/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "pages/Lists/ProfilesList";
import DefaultProjectCard from "pages/Cards/ProjectCards/DefaultProjectCard";

// Overview page components
import Header from "layouts/registry/profile/components/Header";
import PlatformSettings from "layouts/profile/components/PlatformSettings";
import axios from "axios";
import { useLocation } from 'react-router-dom';
// Data
import profilesListData from "layouts/profile/data/profilesListData";

// Images
import homeDecor1 from "assets/images/home-decor-1.jpg";
import homeDecor2 from "assets/images/home-decor-2.jpg";
import homeDecor3 from "assets/images/home-decor-3.jpg";
import homeDecor4 from "assets/images/home-decor-4.jpeg";
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import data from "../Reports/StaffDataSearch/SearchResult";

function Overview() 
{
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));
  var headers   = {'Content-Type': 'multipart/form-data','Authorization':'Bearer '+ userDetails,};
  const [searchResults, setSearchResults] = useState([]);
  const location = useLocation();
  //console.log(location.state.staffID);
 const staffID = location.state.staffID;
 const fetchData=()=>
 {

  const userData = {
    staffID: staffID
  };
 // console.log(staffID);
    axios.post(`${baseUrl}getStaffData`, userData,{headers: {'Authorization': `Bearer ${userDetails.token}` }}).then(response => 
    {
        
          if(response.data.statusCode === 0)
          {
            
            //sessionStorage.setItem('staffID', response.data.data.staffID);
            setSearchResults(response.data.data[0]) 
         }
        if(response.data.statusCode === 1)
        { 
          sessionStorage.clear();            
          toast.error(response.data.message);
          window.location.reload(true);
          const timer = setTimeout(() => 5000);
          return () => clearTimeout(timer)     
         }
      
    }) .catch((error) => {
      if (error.response) {
        //console.log(error.response);
        //console.log("server responded");
      } else if (error.request) {
       // console.log("network error");
      } else {
        //console.log(error);
      }
    });

  }
useEffect(()=>{
  fetchData();
});
 
 // console.log(searchResults);
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox mb={2} />
      <Header info={searchResults}>
        <MDBox mt={5} mb={3}>
          <Grid container spacing={1}>
           
            <Grid item xs={12} md={3} xl={6} sx={{ display: "flex" }}>
              <Divider orientation="vertical" sx={{ ml: -2, mr: 1 }} />
              {searchResults && <ProfileInfoCard searchResults={searchResults}
                // title={searchResults.titlename}
                // description={searchResults.surName}
                info={{
                  FullName:searchResults.staffSurName + ' ' + searchResults.staffFirstName,
                  Mobile: searchResults.staffPhone,
                  Email: searchResults.staffEmail,
                  Department: searchResults.departmentname,
                  Faculty: searchResults.facultyname,
                }}
                social={[
                  {
                    link: "https://www.facebook.com/CreativeTim/",
                    icon: <FacebookIcon />,
                    color: "facebook",
                  },
                  {
                    link: "https://twitter.com/creativetim",
                    icon: <TwitterIcon />,
                    color: "twitter",
                  },
                  {
                    link: "https://www.instagram.com/creativetimofficial/",
                    icon: <InstagramIcon />,
                    color: "instagram",
                  },
                ]}
                action={{ route: "/EditProfileIndex", tooltip: "Edit Profile" }}
                shadow={false}
              />
              }
              <Divider orientation="vertical" sx={{ mx: 0 }} />
            </Grid>
            {/* <Grid item xs={12} md={4} xl={2}>
              <PlatformSettings />
            </Grid> */}
            <Grid item xs={12} xl={4}>
              <ProfilesList title="conversations" profiles={profilesListData} shadow={false} />
            </Grid>
          </Grid>
        </MDBox>
        {/* <MDBox pt={2} px={2} lineHeight={1.25}>
          <MDTypography variant="h6" fontWeight="medium">
            Projects
          </MDTypography>
          <MDBox mb={1}>
            <MDTypography variant="button" color="text">
              Architects design houses
            </MDTypography>
          </MDBox>
        </MDBox> */}
        {/* <MDBox p={2}>
          <Grid container spacing={6}>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor1}
                label="project #2"
                title="modern"
                description="As Uber works through a huge amount of internal management turmoil."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor2}
                label="project #1"
                title="scandinavian"
                description="Music is something that everyone has their own specific opinion about."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team3, name: "Nick Daniel" },
                  { image: team4, name: "Peterson" },
                  { image: team1, name: "Elena Morison" },
                  { image: team2, name: "Ryan Milly" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor3}
                label="project #3"
                title="minimalist"
                description="Different people have different taste, and various types of music."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <DefaultProjectCard
                image={homeDecor4}
                label="project #4"
                title="gothic"
                description="Why would anyone pick blue over pink? Pink is obviously a better color."
                action={{
                  type: "internal",
                  route: "/pages/profile/profile-overview",
                  color: "info",
                  label: "view project",
                }}
                authors={[
                  { image: team4, name: "Peterson" },
                  { image: team3, name: "Nick Daniel" },
                  { image: team2, name: "Ryan Milly" },
                  { image: team1, name: "Elena Morison" },
                ]}
              />
            </Grid>
          </Grid>
        </MDBox> */}
      </Header>
      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
